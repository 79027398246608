import React, { useState, useRef, useEffect } from 'react';
import { CiSearch   } from "react-icons/ci"
import { IoCloseOutline } from "react-icons/io5";
import { Source } from 'interfaces/ISlots';
import usePreRender from 'hooks/usePreRender';
import { cn } from 'utils/utils';

interface StoreSelectorProps {
  sources:  Array<Source>;
  selectedStores: Source[];
  onStoreChange: (stores: Source[]) => void;
  onFilterChange: (search: string) => void;
}

export const StoreSelector: React.FC<StoreSelectorProps> = ({
  sources,
  selectedStores,
  onStoreChange,
  onFilterChange
}) => {
  const { _color } = usePreRender()
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState<Source[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const timeoutRef:any = useRef(null); 

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSearch = (value: string) => {
    setSearchTerm(value);
    if (value.trim() === '') {
      setSuggestions([]);
      return;
    }
  
    const filtered = sources.filter(
      store => 
        store.name.toLowerCase().includes(value.toLowerCase()) &&
        !selectedStores.some(selected => selected._id === store._id)
    );
    if(filtered.length > 0) {
      setSuggestions(filtered);
      setIsOpen(true);
    }else{
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        onFilterChange(value);
      }, 1000)
    }
    
  };

  const handleSelectStore = (store: Source) => {
    if(store._id && !selectedStores.some(selected => selected._id === store._id)){
      onStoreChange([...selectedStores, store]);
      setSearchTerm('');
      setSuggestions([]);
      setIsOpen(false);
    }
  };

  const handleRemoveStore = (storeValue: string) => {
    onStoreChange(selectedStores.filter(store => store._id !== storeValue));
  };

  return (
    <div className="space-y-3">
      <div ref={wrapperRef} className="relative">
        <div className="relative">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => handleSearch(e.target.value)}
            onClick={(e) => { setIsOpen(true); setSuggestions(sources) } }
            placeholder="Buscar sources..."
            className={cn(
              "w-full px-4 pl-10 bg-white border border-[#ACA7A7] rounded-2xl focus:ring-2 focus:ring-indigo-100 transition-colors",
              `focus:border_${_color}`,
            )}
          />
          <CiSearch className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={18} />
        </div>

        {isOpen && suggestions.length > 0 && (
          <div className="absolute z-10 w-full mt-1 bg-white max-h-[500px]  overflow-y-auto custom-scrollbar border border-gray-200 rounded-lg shadow-lg">
            <ul className="py-1">
              {suggestions.map(store => (
                <li key={store._id}>
                  <button
                    type="button"
                    onClick={() => handleSelectStore(store)}
                    className={cn(
                      "w-full px-4 py-2 text-left text-sm",
                      `hover:bg_${_color} hover:text_${_color}`,
                    )}
                  >
                    {store.name}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {selectedStores.length > 0 && (
        <div className="flex flex-wrap gap-2">
          {selectedStores.map((store) => (
            <div
              key={store._id}
              className={cn(
                      "inline-flex items-center gap-1 px-3 py-1.5 rounded-full text-sm",
                      `border border_${_color} text_${_color} bg-gray-50`,
                    )}
              
            >
              {store.name}
              <button
                onClick={() => handleRemoveStore(store._id)}
                className="p-0.5 hover:bg-indigo-100 rounded-full"
              >
                <IoCloseOutline size={14} />
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};