import { useContext, useEffect, useState } from "react"
import AuthContext from "store/context/PublicContext"
import { AppContext } from "store/context/AppContext"
import useSearchMiddlewareOSRM from "hooks/useSearchMiddlewareOSRM"
import { SHIPPING_TYPES_COVERAGES } from "constants/FORM_CONSTANTS"
import Swal from "sweetalert2"
import useCancelToken from "hooks/useCancelToken"
import { post_middleware_combined_search } from "services/middleware_services"

const useAreasScreenUtils = () => {
  const { newCancelToken, isCancel } = useCancelToken()
  const { loading_app, set_loading_app } = useContext(AppContext)
  const [CHANNELS, set_channels] = useState([])
  const [DELIVERY_TYPES, set_delivery_types] = useState([])
  const [TABLE_COVERAGES, set_table_coverages] = useState([])
  const [COURIERS, set_couriers] = useState([])  

  const {
    user: { token },
  } = useContext(AuthContext)

  const { data: data_channels, search_osrm_action: search_osrm_action_channels } = useSearchMiddlewareOSRM({
    entity: "channels",
  })

  const { data: data_schedules, search_osrm_action: search_osrm_action_schedules } = useSearchMiddlewareOSRM({
    entity: "schedules",
  })

  const { data: data_courier, search_osrm_action: search_osrm_action_couriers } = useSearchMiddlewareOSRM({
    entity: "couriers",
  })

  useEffect(() => {
    search_osrm_action_channels({ from: 0, size: 1000, fields: ["id", "name"] })
    search_osrm_action_schedules({ from: 0, size: 1000, fields: ["id", "deliveryType"] })
    search_osrm_action_couriers({ from: 0, size: 1000, fields: ["id"] })
    // eslint-disable-next-line
  }, [])
  
  useEffect(() => {    
    const transform_data = async () => {
      try {
        const channels_from_req = data_channels?.records
        const schedule_from_req = data_schedules?.records
        const couriers_from_req = data_courier?.records

        set_channels(
          channels_from_req?.map((chl: any) => ({
            id: chl?.id,
            label: chl?.name,
            value: chl?.name,
          })),
        )
        set_delivery_types(
          schedule_from_req?.map((sch: any) => ({
            id: sch?.id,
            label: sch?.deliveryType,
            value: sch?.deliveryType,
          })),
        )
        set_couriers(
          couriers_from_req?.map((courier: any) => ({
            id: courier?.id,
            label: courier?.id,
            value: courier?.id,
          })),
        )
      } catch (error: any) {
        Swal.fire({
          title: "Error",
          text: JSON.stringify(error?.response?.data?.message),
          icon: "error",
        })
      }
    }
    transform_data()
    // eslint-disable-next-line
  }, [data_channels, data_schedules, data_courier])

  const post_list_table_combined = async (body: any) => {
    set_loading_app(true)
    try {
      const {
        data: { code, message },
      } = await post_middleware_combined_search(`coverages/combination`, body, token, newCancelToken())
      
      if(code === 200) {
        set_loading_app(false)
        set_table_coverages(
          message?.map((item: any) => ({
            ...item,
            leadTime: {
              value: 0,
              unit: ''
            }
          })),
        )
      } else {
        set_loading_app(false)
        Swal.fire({
          title: "Error",
          text: message,
          icon: "error",
        })
      }
    } catch (error: any) {
      set_loading_app(false)
      Swal.fire({
        title: "Error",
        text: JSON.stringify(error?.response?.data?.message),
        icon: "error",
      })
      if (isCancel(error)) return
    }
  }

  return {
    loading_app,
    CHANNELS,
    SHIPPING_TYPES_COVERAGES,
    DELIVERY_TYPES,
    COURIERS,
    TABLE_COVERAGES,
    set_table_coverages,
    post_list_table_combined,
  }
}

export default useAreasScreenUtils
