export const DAYS_OF_WEEK_NUMBER = [
  { id: 1, label: "Lunes", value: 1 },
  { id: 2, label: "Martes", value: 2 },
  { id: 3, label: "Miércoles", value: 3 },
  { id: 4, label: "Jueves", value: 4 },
  { id: 5, label: "Viernes", value: 5 },
  { id: 6, label: "Sábado", value: 6 },
  { id: 7, label: "Domingo", value: 7 },
]

export const UNIT_TIME = [
  { id: 1, label: "Hora(s)", value: "h" },
  { id: 2, label: "Día(s)", value: "d" },
]

export const SHIPPING_TYPES = [
  { id: 1, label: "Despacho a domicilio", value: "HD" },
  { id: 2, label: "Retiro en tienda", value: "SP" },
  { id: 3, label: "Devolución", value: "RT" },
  { id: 4, label: "Transferencia", value: "TR" },
]

export const SHIPPING_TYPES_COVERAGES = [
  { id: 1, label: "Despacho a domicilio", value: "Despacho a domicilio" },
  { id: 2, label: "Retiro en tienda", value: "Retiro en tienda" },
  { id: 3, label: "Devolución", value: "Devolución" },
  { id: 4, label: "Transferencia", value: "Transferencia" },
]

export const HOURS_OF_DAY = [
  { id: 0, label: "00:00", value: "00:00" },
  { id: 1, label: "00:01", value: "00:01" },
  { id: 2, label: "00:15", value: "00:15" },
  { id: 3, label: "00:30", value: "00:30" },
  { id: 4, label: "00:45", value: "00:45" },

  { id: 5, label: "01:00", value: "01:00" },
  { id: 6, label: "01:15", value: "01:15" },
  { id: 7, label: "01:30", value: "01:30" },
  { id: 8, label: "01:45", value: "01:45" },

  { id: 9, label: "02:00", value: "02:00" },
  { id: 10, label: "02:15", value: "02:15" },
  { id: 11, label: "02:30", value: "02:30" },
  { id: 12, label: "02:45", value: "02:45" },

  { id: 13, label: "03:00", value: "03:00" },
  { id: 14, label: "03:15", value: "03:15" },
  { id: 15, label: "03:30", value: "03:30" },
  { id: 16, label: "03:45", value: "03:45" },

  { id: 17, label: "04:00", value: "04:00" },
  { id: 18, label: "04:15", value: "04:15" },
  { id: 19, label: "04:30", value: "04:30" },
  { id: 20, label: "04:45", value: "04:45" },

  { id: 21, label: "05:00", value: "05:00" },
  { id: 22, label: "05:15", value: "05:15" },
  { id: 23, label: "05:30", value: "05:30" },
  { id: 24, label: "05:45", value: "05:45" },

  { id: 25, label: "06:00", value: "06:00" },
  { id: 26, label: "06:15", value: "06:15" },
  { id: 27, label: "06:30", value: "06:30" },
  { id: 28, label: "06:45", value: "06:45" },

  { id: 29, label: "07:00", value: "07:00" },
  { id: 30, label: "07:15", value: "07:15" },
  { id: 31, label: "07:30", value: "07:30" },
  { id: 32, label: "07:45", value: "07:45" },

  { id: 33, label: "08:00", value: "08:00" },
  { id: 34, label: "08:15", value: "08:15" },
  { id: 35, label: "08:30", value: "08:30" },
  { id: 36, label: "08:45", value: "08:45" },

  { id: 37, label: "09:00", value: "09:00" },
  { id: 38, label: "09:15", value: "09:15" },
  { id: 39, label: "09:30", value: "09:30" },
  { id: 40, label: "09:45", value: "09:45" },

  { id: 41, label: "10:00", value: "10:00" },
  { id: 42, label: "10:15", value: "10:15" },
  { id: 43, label: "10:30", value: "10:30" },
  { id: 44, label: "10:45", value: "10:45" },

  { id: 45, label: "11:00", value: "11:00" },
  { id: 46, label: "11:15", value: "11:15" },
  { id: 47, label: "11:30", value: "11:30" },
  { id: 48, label: "11:45", value: "11:45" },

  { id: 49, label: "12:00", value: "12:00" },
  { id: 50, label: "12:15", value: "12:15" },
  { id: 51, label: "12:30", value: "12:30" },
  { id: 52, label: "12:45", value: "12:45" },

  { id: 53, label: "13:00", value: "13:00" },
  { id: 54, label: "13:15", value: "13:15" },
  { id: 55, label: "13:30", value: "13:30" },
  { id: 56, label: "13:45", value: "13:45" },

  { id: 57, label: "14:00", value: "14:00" },
  { id: 58, label: "14:15", value: "14:15" },
  { id: 59, label: "14:30", value: "14:30" },
  { id: 60, label: "14:45", value: "14:45" },

  { id: 61, label: "15:00", value: "15:00" },
  { id: 62, label: "15:15", value: "15:15" },
  { id: 63, label: "15:30", value: "15:30" },
  { id: 64, label: "15:45", value: "15:45" },

  { id: 65, label: "16:00", value: "16:00" },
  { id: 66, label: "16:15", value: "16:15" },
  { id: 67, label: "16:30", value: "16:30" },
  { id: 68, label: "16:45", value: "16:45" },

  { id: 69, label: "17:00", value: "17:00" },
  { id: 70, label: "17:15", value: "17:15" },
  { id: 71, label: "17:30", value: "17:30" },
  { id: 72, label: "17:45", value: "17:45" },

  { id: 73, label: "18:00", value: "18:00" },
  { id: 74, label: "18:15", value: "18:15" },
  { id: 75, label: "18:30", value: "18:30" },
  { id: 76, label: "18:45", value: "18:45" },

  { id: 77, label: "19:00", value: "19:00" },
  { id: 78, label: "19:15", value: "19:15" },
  { id: 79, label: "19:30", value: "19:30" },
  { id: 80, label: "19:45", value: "19:45" },

  { id: 81, label: "20:00", value: "20:00" },
  { id: 82, label: "20:15", value: "20:15" },
  { id: 83, label: "20:30", value: "20:30" },
  { id: 84, label: "20:45", value: "20:45" },

  { id: 85, label: "21:00", value: "21:00" },
  { id: 86, label: "21:15", value: "21:15" },
  { id: 87, label: "21:30", value: "21:30" },
  { id: 88, label: "21:45", value: "21:45" },

  { id: 89, label: "22:00", value: "22:00" },
  { id: 90, label: "22:15", value: "22:15" },
  { id: 91, label: "22:30", value: "22:30" },
  { id: 92, label: "22:45", value: "22:45" },

  { id: 93, label: "23:00", value: "23:00" },
  { id: 94, label: "23:15", value: "23:15" },
  { id: 95, label: "23:30", value: "23:30" },
  { id: 96, label: "23:45", value: "23:45" },
  { id: 97, label: "23:59", value: "23:59" },
]

export const TIMEZONE = [
  'America/Adak',
  'America/Anchorage',
  'America/Anguilla',
  'America/Antigua',
  'America/Araguaina',
  'America/Argentina/Buenos_Aires',
  'America/Argentina/Catamarca',
  'America/Argentina/ComodRivadavia',
  'America/Argentina/Cordoba',
  'America/Argentina/Jujuy',
  'America/Argentina/La_Rioja',
  'America/Argentina/Mendoza',
  'America/Argentina/Rio_Gallegos',
  'America/Argentina/Salta',
  'America/Argentina/San_Juan',
  'America/Argentina/San_Luis',
  'America/Argentina/Tucuman',
  'America/Argentina/Ushuaia',
  'America/Aruba',
  'America/Asuncion',
  'America/Atikokan',
  'America/Atka',
  'America/Bahia',
  'America/Bahia_Banderas',
  'America/Barbados',
  'America/Belem',
  'America/Belize',
  'America/Blanc-Sablon',
  'America/Boa_Vista',
  'America/Bogota',
  'America/Boise',
  'America/Buenos_Aires',
  'America/Cambridge_Bay',
  'America/Campo_Grande',
  'America/Cancun',
  'America/Caracas',
  'America/Catamarca',
  'America/Cayenne',
  'America/Cayman',
  'America/Chicago',
  'America/Chihuahua',
  'America/Coral_Harbour',
  'America/Cordoba',
  'America/Costa_Rica',
  'America/Creston',
  'America/Cuiaba',
  'America/Curacao',
  'America/Danmarkshavn',
  'America/Dawson',
  'America/Dawson_Creek',
  'America/Denver',
  'America/Detroit',
  'America/Dominica',
  'America/Edmonton',
  'America/Eirunepe',
  'America/El_Salvador',
  'America/Ensenada',
  'America/Fort_Wayne',
  'America/Fortaleza',
  'America/Glace_Bay',
  'America/Godthab',
  'America/Goose_Bay',
  'America/Grand_Turk',
  'America/Grenada',
  'America/Guadeloupe',
  'America/Guatemala',
  'America/Guayaquil',
  'America/Guyana',
  'America/Halifax',
  'America/Havana',
  'America/Hermosillo',
  'America/Indiana/Indianapolis',
  'America/Indiana/Knox',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Tell_City',
  'America/Indiana/Vevay',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Indianapolis',
  'America/Inuvik',
  'America/Iqaluit',
  'America/Jamaica',
  'America/Jujuy',
  'America/Juneau',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  'America/Knox_IN',
  'America/Kralendijk',
  'America/La_Paz',
  'America/Lima',
  'America/Los_Angeles',
  'America/Louisville',
  'America/Lower_Princes',
  'America/Maceio',
  'America/Managua',
  'America/Manaus',
  'America/Marigot',
  'America/Martinique',
  'America/Matamoros',
  'America/Mazatlan',
  'America/Mendoza',
  'America/Menominee',
  'America/Merida',
  'America/Metlakatla',
  'America/Mexico_City',
  'America/Miquelon',
  'America/Moncton',
  'America/Monterrey',
  'America/Montevideo',
  'America/Montreal',
  'America/Montserrat',
  'America/Nassau',
  'America/New_York',
  'America/Nipigon',
  'America/Nome',
  'America/Noronha',
  'America/North_Dakota/Beulah',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/Ojinaga',
  'America/Panama',
  'America/Pangnirtung',
  'America/Paramaribo',
  'America/Phoenix',
  'America/Port_of_Spain',
  'America/Port-au-Prince',
  'America/Porto_Acre',
  'America/Porto_Velho',
  'America/Puerto_Rico',
  'America/Rainy_River',
  'America/Rankin_Inlet',
  'America/Recife',
  'America/Regina',
  'America/Resolute',
  'America/Rio_Branco',
  'America/Rosario',
  'America/Santa_Isabel',
  'America/Santarem',
  'America/Santiago',
  'America/Santo_Domingo',
  'America/Sao_Paulo',
  'America/Scoresbysund',
  'America/Shiprock',
  'America/Sitka',
  'America/St_Barthelemy',
  'America/St_Johns',
  'America/St_Kitts',
  'America/St_Lucia',
  'America/St_Thomas',
  'America/St_Vincent',
  'America/Swift_Current',
  'America/Tegucigalpa',
  'America/Thule',
  'America/Thunder_Bay',
  'America/Tijuana',
  'America/Toronto',
  'America/Tortola',
  'America/Vancouver',
  'America/Virgin',
  'America/Whitehorse',
  'America/Winnipeg',
  'America/Yakutat',
  'America/Yellowknife',
  'Brazil/Acre',
  'Brazil/DeNoronha',
  'Brazil/East',
  'Brazil/West',
  'Canada/Atlantic',
  'Canada/Central',
  'Canada/Eastern',
  'Canada/East-Saskatchewan',
  'Canada/Mountain',
  'Canada/Newfoundland',
  'Canada/Pacific',
  'Canada/Saskatchewan',
  'Canada/Yukon',
  'CET',
  'Chile/Continental',
  'Chile/EasterIsland',
  'CST6CDT',
  'Cuba',
  'EET',
  'Egypt',
  'Eire',
  'EST',
  'EST5EDT',
  'Etc/GMT',
  'Etc/GMT+0',
  'Etc/GMT+1',
  'Etc/GMT+10',
  'Etc/GMT+11',
  'Etc/GMT+12',
  'Etc/GMT+2',
  'Etc/GMT+3',
  'Etc/GMT+4',
  'Etc/GMT+5',
  'Etc/GMT+6',
  'Etc/GMT+7',
  'Etc/GMT+8',
  'Etc/GMT+9',
  'Etc/GMT0',
  'Etc/GMT-0',
  'Etc/GMT-1',
  'Etc/GMT-10',
  'Etc/GMT-11',
  'Etc/GMT-12',
  'Etc/GMT-13',
  'Etc/GMT-14',
  'Etc/GMT-2',
  'Etc/GMT-3',
  'Etc/GMT-4',
  'Etc/GMT-5',
  'Etc/GMT-6',
  'Etc/GMT-7',
  'Etc/GMT-8',
  'Etc/GMT-9',
  'Etc/Greenwich',
  'Etc/UCT',
  'Etc/Universal',
  'Etc/UTC',
  'Etc/Zulu',
  'GB',
  'GB-Eire',
  'GMT',
  'GMT+0',
  'GMT0',
  'GMT-0',
  'Greenwich',
  'Hongkong',
  'HST',
  'Iceland',
  'Indian/Antananarivo',
  'Indian/Chagos',
  'Indian/Christmas',
  'Indian/Cocos',
  'Indian/Comoro',
  'Indian/Kerguelen',
  'Indian/Mahe',
  'Indian/Maldives',
  'Indian/Mauritius',
  'Indian/Mayotte',
  'Indian/Reunion',
  'Iran',
  'Israel',
  'Jamaica',
  'Japan',
  'Kwajalein',
  'Libya',
  'MET',
  'Mexico/BajaNorte',
  'Mexico/BajaSur',
  'Mexico/General',
  'Mideast/Riyadh87',
  'Mideast/Riyadh88',
  'Mideast/Riyadh89',
  'MST',
  'MST7MDT',
  'Navajo',
  'NZ',
  'Poland',
  'Portugal',
  'PRC',
  'PST8PDT',
  'ROK',
  'Singapore',
  'Turkey',
  'UCT',
  'Universal',
  'US/Alaska',
  'US/Aleutian',
  'US/Arizona',
  'US/Central',
  'US/Eastern',
  'US/East-Indiana',
  'US/Hawaii',
  'US/Indiana-Starke',
  'US/Michigan',
  'US/Mountain',
  'US/Pacific',
  'US/Pacific-New',
  'US/Samoa',
  'UTC',
  'WET',
  'W-SU',
  'Zulu'
]
