import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const generateTimeOptions = () => {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const timeString = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
        options.push(timeString);
      }
    }
    return options;
};

export const DAYS = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
] as const;

export const createEmptySchedule = (): any => {
  return DAYS.reduce(
    (acc, day) => ({
      ...acc,
      [day]: { 
        from: "09:00",
        to: "17:00",
        capacity: 0,
        status: true,
        label: "Slot", 
      }
    }),
    {}
  );
};

export const areObjectsDifferent = (obj1:any, obj2:any) => {
  if (typeof obj1 !== typeof obj2) return true;

  if (typeof obj1 !== "object" || obj1 === null || obj2 === null) {
    return obj1 !== obj2; // Primitive comparison
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return true;

  for (let key of keys1) {
    if (!keys2.includes(key) || areObjectsDifferent(obj1[key], obj2[key])) {
      return true;
    }
  }

  return false; // No differences found
};


export const removeEmptyKeys = (obj:any) =>{
  for (const key in obj) {
    if (obj[key] === null || obj[key] === undefined || obj[key] === '' || (Array.isArray(obj[key]) && obj[key].length === 0) || 
       (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0)) {
        console.log("deleted empty key "+key);
      delete obj[key];
    }
  }
  return obj;
}