import { format_date } from "helpers/datetime_helper"
import React, { useRef, useEffect } from "react"
import {
  FaCheck,
  FaUserCog,
  FaBoxOpen,
  FaBarcode,
  FaBox,
  FaClipboardCheck,
  FaBoxes,
  FaShippingFast,
  FaTruck,
  FaStore,
  FaMapMarkerAlt,
  FaCreditCard,
  FaHandPaper,
  FaBan,
  FaUndo,
} from "react-icons/fa"
import translatesObj from "overrides/theme/entel/base/translates/translation.json"

export interface Status {
  state: { name: string }
  date: string
  hour: string
}

interface StatusTimelineProps {
  statuses: Status[]
}

const statusses: any = translatesObj

const getStatusIcon = (status: string) => {
  const icons: { [key: string]: React.ReactElement } = {
    CONFIRMED: <FaCheck className="w-6 h-6" />,
    ASSIGNED: <FaUserCog className="w-6 h-6" />,
    IN_PREPARATION: <FaBoxOpen className="w-6 h-6" />,
    PICKING_ID_GENERATED: <FaBarcode className="w-6 h-6" />,
    PICKING_IN_PROGRESS: <FaBox className="w-6 h-6" />,
    VALIDATE_PICKING: <FaClipboardCheck className="w-6 h-6" />,
    PICKING_CONFIRMED: <FaCheck className="w-6 h-6" />,
    PICKING_COMPLETED_PENDING_PACKAGE: <FaBoxes className="w-6 h-6" />,
    PACKED_AND_READY_FOR_PICKUP: <FaBox className="w-6 h-6" />,
    DELIVERED_TO_COURIER: <FaShippingFast className="w-6 h-6" />,
    DELIVER_TO_COURIER: <FaShippingFast className="w-6 h-6" />,
    IN_TRANSIT: <FaTruck className="w-6 h-6" />,
    ORDER_RECEIVED: <FaCheck className="w-6 h-6" />,
    DELIVERED: <FaCheck className="w-6 h-6" />,
    DELIVERED_IN_STORE: <FaStore className="w-6 h-6" />,
    READY_FOR_PICKUP: <FaMapMarkerAlt className="w-6 h-6" />,
    PICKUP_AVAILABLE: <FaMapMarkerAlt className="w-6 h-6" />,
    SUCCESSFUL_PAYMENT: <FaCreditCard className="w-6 h-6" />,
    PICKED_UP: <FaHandPaper className="w-6 h-6" />,
    CANCELED: <FaBan className="w-6 h-6" />,
    DELIVERED_IN_SOURCE: <FaStore className="w-6 h-6" />,
    ORDER_RETURNED: <FaUndo className="w-6 h-6" />,
  }

  return icons[status] || <FaCheck className="w-6 h-6" />
}

const StatusTimeline: React.FC<StatusTimelineProps> = ({ statuses }) => {
  const timelineRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (timelineRef.current && containerRef.current) {
      const scrollPosition = timelineRef.current.scrollWidth - containerRef.current.offsetWidth
      timelineRef.current.scrollLeft = scrollPosition
    }
  }, [statuses])

  return (
    <div ref={containerRef} className="relative w-full bg-white py-4 rounded-md">
      {/* Continuous Timeline Line */}
      <div className="absolute h-0.5 bg-gray-300 top-[1.75rem] left-0 right-0 transform translate-y-4" />

      <div ref={timelineRef} className="w-full overflow-x-auto pb-6 pt-2 px-4 scroll-smooth">
        <div className="flex space-x-8 min-w-max">
          {statuses?.map((status, index) => {
            console.log("status", status)
            const isLast = index === statuses.length - 1
            const isCompleted = index < statuses.length - 1

            return (
              <div
                key={index}
                className={`flex flex-col items-center relative ${
                  isLast ? "scale-110 text-blue-600" : "text-gray-600"
                }`}
              >
                {/* Icon Circle */}
                <div
                  className={`w-10 h-10 rounded-full flex items-center justify-center mb-2 z-10 transition-all duration-300
                    ${
                      isLast
                        ? "bg-blue-100 text-blue-600 ring-4 ring-blue-50"
                        : isCompleted
                        ? "bg-green-50 text-green-600"
                        : "bg-gray-100"
                    }`}
                >
                  {getStatusIcon(status?.state?.name)}
                </div>

                {/* Status Text */}
                <span
                  className={`text-sm font-medium mb-1 whitespace-nowrap transition-colors duration-300
                  ${isLast ? "text-blue-600" : isCompleted ? "text-green-600" : "text-gray-700"}`}
                >
                  {/* {formatStatus(status?.state?.name)} */}
                  {statusses[status?.state?.name]?.title || status?.state?.name}
                </span>

                {/* Date & Time */}
                <span className="text-xs text-gray-500">{format_date(new Date(status.date))}</span>
                <span className="text-xs text-gray-500">{status.hour}</span>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default StatusTimeline
