export const item_type = [
  { id: 1, value: "Default", label: "Default" },
  { id: 2, value: "Service", label: "Servicio" },
  { id: 3, value: "Warranty", label: "Garantía" },
]

export const parsed_body_to_send = (_data: any) => {
  const {
    name,
    sku,
    type,
    storePickup,
    safety,
    enabled,
    categories,
    barcodes,
    logisticAttributes,
    preparationTime,
    custom,
  } = _data

  let data = {
    name,
    sku,
    safety: safety ? Number(safety) : 0,
    type,
    storePickup,
    enabled,
    categories: categories ? categories?.split(",") : [],
    barcodes: barcodes ? barcodes?.split(",") : [],
    logisticAttributes: {
      ...logisticAttributes,
      volume: Number(logisticAttributes?.volume),
    },
    preparationTime,
    custom: parsedDinamicDataOnSave(custom),
  }

  return data
}

export const fill_form_from_data = (_data: any) => {
  const {
    name,
    sku,
    type,
    storePickup,
    safety,
    enabled,
    categories,
    barcodes,
    logisticAttributes,
    preparationTime,
    custom,
  } = _data

  let data = {
    name,
    sku,
    type,
    safety: safety ? Number(safety) : 0,
    storePickup,
    enabled,
    categories: categories ? categories?.join(",") : "",
    barcodes: barcodes ? barcodes?.join(",") : "",
    logisticAttributes: {
      ...logisticAttributes,
      volume: Number(logisticAttributes?.volume),
    },
    preparationTime,
    custom: loadDinamicFields(custom),
  }

  return data
}

const parsedDinamicDataOnSave = (field: any) => {
  let values: any = {}
  ;(field || []).map((item: any) => {
    if (item?.key?.includes(".")) {
      let [key1, key2] = item?.key?.split(".")
      if (values.hasOwnProperty(key1)) {
        values[key1] = { ...values[key1], [key2]: assignDataType(item.type, item.value) }
      } else {
        Object.assign(values, { [key1]: { [key2]: assignDataType(item.type, item.value) } })
      }
      return true
    }
    Object.assign(values, { [item.key]: assignDataType(item.type, item.value) })
    return true
  })
  return values
}

const assignDataType = (type: any, value: any) => {
  try {
    switch (type) {
      case "array-string":
        return value && value?.split("+")
      case "number":
        return Number(value) || 0
      case "string":
        return value?.toString() || JSON.stringify(value)
      default:
        return JSON.parse(value)
    }
  } catch (error) {
    return value
  }
}

const getDataType = (value: any) => {
  let dataType = typeof value
  switch (dataType) {
    case "object":
      const isArray = Array.isArray(value)
      return isArray && "array-string"
    case "number":
      return "number"
    case "string":
      return "string"
    case "boolean":
      return "boolean"
    default:
      return "string"
  }
}

const loadDinamicFields = (field = {}) => {
  let values: any = []
  if (Object.keys(field).length > 0) {
    Object.entries(field).map(([key, value]: any) => {
      let valToPush = {
        key,
        type: getDataType(value),
        value: getDataType(value) === "array-string" ? value.join("+") : value,
      }
      if (value && typeof value === "object" && !Array.isArray(value)) {
        Object.entries(value).map(([key_in_value, val_in_value]: any) => {
          valToPush = {
            key: `${key}.${key_in_value}`,
            type: getDataType(val_in_value),
            value: getDataType(val_in_value) === "array-string" ? val_in_value.join("+") : val_in_value,
          }
          return values.push(valToPush)
        })
        return true
      }
      return values.push(valToPush)
    })
  }
  return values
}
