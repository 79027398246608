import React, { useState } from 'react';

interface TruncateTextProps {
  items: string[];
  limit?: number;
}

export function TruncateText({ items, limit = 3 }: TruncateTextProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  
  const displayItems = isExpanded ? items : items?.slice(0, limit);

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="text-gray-700">
      <span className='capitalize'>
        {displayItems?.map((item, index) => (
          <React.Fragment key={index}>
            {index > 0 && ', '}
            {item?.toLowerCase()}
          </React.Fragment>
        ))}
      </span>
      {items?.length > limit && (
        // eslint-disable-next-line
        <a
          href="#"
          onClick={handleClick}
          className="ml-1 text-pink-500 hover:text-pink-600 text-sm font-medium cursor-pointer"
        >
          {isExpanded ? 'Ver menos' : 'Ver más'}
        </a>
      )}
    </div>
  );
}