import { Breadcrumb, ButtonGroup, TitleScreen } from "components/Core"
import { FormItem } from "components/FormEntities"
import useForm from "hooks/useForm"
import useSearchMiddlewareOSRM from "hooks/useSearchMiddlewareOSRM"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import item_values from "utils/forms_default/item_values"
import schemaValidationItems from "utils/yup/items_schema"
import { parsed_body_to_send, fill_form_from_data } from "components/FormEntities/FormItem/item.config"
import { useFieldArray } from "react-hook-form"
import useItemsResources from "hooks/entities/useItemsResources"
import Swal from "sweetalert2"

const EditScreen = () => {
  const navigate = useNavigate()
  const { item_id } = useParams()
  const [item, set_item] = useState<any>()
  const { control, errors, reset, handleSubmit, watch } = useForm({
    defaultValues: item_values,
    schema: schemaValidationItems,
  })
  const { set_loading_app, put_load_items_action } = useItemsResources()
  const custom_field_form = useFieldArray({ control, name: "custom" })
  const { data: data_items, search_osrm_action: search_osrm_action_items } = useSearchMiddlewareOSRM({
    entity: "items",
  })

  useEffect(() => {
    search_osrm_action_items({ from: 0, size: 1000, filters: { sku: item_id } })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (Boolean(data_items?.records.length)) {
      const get_item = async () => {
        let item_from_req = data_items?.records[0]
        set_item(item_from_req)
      }
      get_item()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_items])

  useEffect(() => {
    // RESET FORM TO FILL FIELDS
    item && reset(fill_form_from_data(item))
    // eslint-disable-next-line
  }, [item])

  const on_submit = async (data: any) => {
    try {
      set_loading_app(true)
      const body = parsed_body_to_send(data)
      let res: any = await put_load_items_action({ body })
      if (res?.data?.code === 200) {
        Swal.fire({
          title: "Hecho",
          text: "Artículo creado",
          icon: "success",
        })
        navigate(`/items/${body.sku}`)
      }
      set_loading_app(false)
    } catch (error: any) {
      Swal.fire({
        title: "Error",
        text: JSON.stringify(error?.response?.data?.message),
        icon: "error",
      })
      set_loading_app(false)
    }
  }
  console.log("errors", errors)

  return (
    <div className="w-full h-full flex flex-col items-start justify-start">
      <Breadcrumb
        data={[
          { name: "Listado de artículos", href: "/items" },
          { name: "Edición de artículos", href: "/items/new" },
        ]}
      />
      <div className="w-full flex flex-col justify-center items-center py-4 px-6">
        <TitleScreen title="Edición de artículo" string_id={item_id} />
        {/* <p>ItemEditScreen</p> */}
      </div>
      <form className="w-full flex flex-col py-2 px-4 lg:px-10" onSubmit={handleSubmit(on_submit)}>
        <div className="w-full flex flex-row items-center justify-end lg:pb-10 py-2">
          <ButtonGroup />
        </div>
        <FormItem control={control} errors={errors} watch={watch} custom_field_form={custom_field_form} />
      </form>
    </div>
  )
}

export default EditScreen
