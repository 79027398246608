import PublicRouter from "router/PublicRouter"
import { PublicProvider } from "store/context/PublicContext"
import AppProvider from "store/providers/AppProvider"
import { Flowbite } from "flowbite-react"
import get_flowbite_theme from "utils/get_flowbite_theme/base"
import "assets/css/global.css"
import { Auth0Provider } from "@auth0/auth0-react"
import "webcomponent/JsonViewer/index"
import { Env, MultiClient } from "config"
import {
  auth0Domain_dev,
  auth0Domain_uat,
  auth0ClientId_dev,
  auth0ClientId_uat,
  auth0ClientId_prod,
  auth0Domain_prod,
} from "constants/APP_CONSTANTS"

const AppContainer = () => {
  let [, _env] = MultiClient.get_user_env(null, Env.clients, Env.env, Env.defaults)
  const cred =
    _env === "prod"
      ? { clientId: auth0ClientId_prod, domain: auth0Domain_prod }
      : _env === "uat"
      ? { clientId: auth0ClientId_uat, domain: auth0Domain_uat }
      : { clientId: auth0ClientId_dev, domain: auth0Domain_dev }

  return (
    <Flowbite theme={{ theme: get_flowbite_theme }}>
      <Auth0Provider
        clientId={cred.clientId || ""}
        domain={cred.domain || ""}
        authorizationParams={{
          redirect_uri: `${window.location.origin}/callback`,
        }}
      >
        <PublicProvider>
          <AppProvider>
            <PublicRouter />
          </AppProvider>
        </PublicProvider>
      </Auth0Provider>
    </Flowbite>
  )
}

export default AppContainer
