/* eslint-disable */

// Areas
import AreasScreenListScreen from "screens/AreasScreen/ListScreen"
import AreasScreenDetailScreen from "screens/AreasScreen/DetailScreen"
import AreasScreenNewScreen from "screens/AreasScreen/NewScreen"

// Bulk
import BulkScreenMainScreen from "screens/BulkScreen/MainScreen"

// Bussiness
import BusinessScreenMainScreen from "screens/BusinessScreen/MainScreen"

// Capacity
import CapacityScreenListScreen from "screens/CapacityScreen/ListScreen"
import CapacityScreenNewScreen from "screens/CapacityScreen/NewScreen"

// Channels
import ChannelsScreenListScreen from "screens/ChannelsScreen/ListScreen"
import ChannelsScreenNewScreen from "screens/ChannelsScreen/NewScreen"
import ChannelsScreenEditScreen from "screens/ChannelsScreen/EditScreen"

// Couriers
import CouriersScreenListScreen from "screens/CouriersScreen/ListScreen"
import CouriersScreenDetailScreen from "screens/CouriersScreen/DetailScreen"
import CouriersScreenEditScreen from "screens/CouriersScreen/EditScreen"
import CouriersScreenNewScreen from "screens/CouriersScreen/NewScreen"

// Coverages
import CoveragesScreenListScreen from "screens/CoveragesScreen/ListScreen"
import CoveragesScreenDetailScreen from "screens/CoveragesScreen/DetailScreen"
import CoveragesScreenEditScreen from "screens/CoveragesScreen/EditScreen"
import CoveragesScreenNewScreen from "screens/CoveragesScreen/NewScreen"

// Items
import ItemsScreenListScreen from "screens/ItemsScreen/ListScreen"
import ItemsScreenDetailScreen from "screens/ItemsScreen/DetailScreen"
import ItemsScreenNewScreen from "screens/ItemsScreen/NewScreen"
import ItemsScreenEditScreen from "screens/ItemsScreen/EditScreen"

// Locations
import LocationsScreenListScreen from "screens/LocationsScreen/ListScreen"
import LocationsScreenEditScreen from "screens/LocationsScreen/EditScreen"
import LocationsScreenNewScreen from "screens/LocationsScreen/NewScreen"

// Orders
import OrdersScreenListScreen from "overrides/theme/entel/base/screens/OrdersScreen/ListScreen"
import OrdersScreenDetailScreen from "overrides/theme/entel/base/screens/OrdersScreen/DetailScreen"
import OrdersScreenNewScreen from "screens/OrdersScreen/NewScreen"

// Reports
import ReportsScreenMainScreen from "screens/ReportsScreen/MainScreen"

// Routes
import RoutesScreenListScreen from "screens/RoutesScreen/ListScreen"
import RoutesScreenDetailScreen from "screens/RoutesScreen/DetailScreen"
import RoutesScreenEditScreen from "screens/RoutesScreen/EditScreen"
import RoutesScreenNewScreen from "screens/RoutesScreen/NewScreen"

// Schedule
import ScheduleScreenListScreen from "screens/ScheduleScreen/ListScreen"
import ScheduleScreenDetailScreen from "screens/ScheduleScreen/DetailScreen"
import ScheduleScreenEditScreen from "screens/ScheduleScreen/EditScreen"
import ScheduleScreenNewScreen from "screens/ScheduleScreen/NewScreen"

// Slots
import SlotsScreenListScreen from "screens/SlotsScreen/ListScreen"

// Settings
import SettingsScreenMainScreen from "screens/SettingsScreen/MainScreen"
import SettingsScreenSafetyScreen from "screens/SettingsScreen/SafetyScreen"

// Sgs
import SgsScreenListScreen from "overrides/theme/entel/base/screens/SgsScreen/ListScreen"
import SgsScreenDetailScreen from "overrides/theme/entel/base/screens/SgsScreen/DetailScreen"

// Sources
import SourcesScreenListScreen from "screens/SourcesScreen/ListScreen"
import SourcesScreenDetailScreen from "screens/SourcesScreen/DetailScreen"
import SourcesScreenNewScreen from "screens/SourcesScreen/NewScreen"
import SourcesScreenEditScreen from "screens/SourcesScreen/EditScreen"
import SourcesScreenManageScreen from "screens/SourcesScreen/ManageScreen"

// Stock
import StockScreenListScreen from "screens/StockScreen/ListScreen"
import StockScreenDetailScreen from "screens/StockScreen/DetailScreen"
import StockScreenNewScreen from "screens/StockScreen/NewScreen"

// Dashboard
import HomeScreen from "screens/HomeScreen/HomeScreen"

const listRoutes = [
  // areasScreen
  {
    name: "AreasScreenListScreen",
    path: "areas",
    element: AreasScreenListScreen,
  },
  {
    name: "AreasScreenDetailScreen",
    path: "areas/:area_id",
    element: AreasScreenDetailScreen,
  },
  {
    name: "AreasScreenNewScreen",
    path: "areas/new",
    element: AreasScreenNewScreen,
  },
  // BulkScreen
  {
    name: "BulkScreenMainScreen",
    path: "bulk",
    element: BulkScreenMainScreen,
  },
  // CapacityScreen
  {
    name: "CapacityScreenListScreen",
    path: "capacity",
    element: CapacityScreenListScreen,
  },
  {
    name: "CapacityScreenNewScreen",
    path: "capacity/new",
    element: CapacityScreenNewScreen,
  },
  // ChannelsScreen
  {
    name: "ChannelsScreenListScreen",
    path: "channels",
    element: ChannelsScreenListScreen,
  },
  {
    name: "ChannelsScreenEditScreen",
    path: "channels/:channel_id/edit",
    element: ChannelsScreenEditScreen,
  },
  {
    name: "ChannelsScreenNewScreen",
    path: "channels/new",
    element: ChannelsScreenNewScreen,
  },
  // CouriersScreen
  {
    name: "CouriersScreenListScreen",
    path: "couriers",
    element: CouriersScreenListScreen,
  },
  {
    name: "CouriersScreenDetailScreen",
    path: "couriers/:courier_id",
    element: CouriersScreenDetailScreen,
  },
  {
    name: "CouriersScreenEditScreen",
    path: "couriers/:courier_id/edit",
    element: CouriersScreenEditScreen,
  },
  {
    name: "CouriersScreenNewScreen",
    path: "couriers/new",
    element: CouriersScreenNewScreen,
  },
  // CoveragesScreen
  {
    name: "CoveragesScreenListScreen",
    path: "coverages",
    element: CoveragesScreenListScreen,
  },
  {
    name: "CoveragesScreenDetailScreen",
    path: "coverages/:coverage_id",
    element: CoveragesScreenDetailScreen,
  },
  {
    name: "CoveragesScreenEditScreen",
    path: "coverages/:coverage_id/edit",
    element: CoveragesScreenEditScreen,
  },
  {
    name: "CoveragesScreenNewScreen",
    path: "coverages/new",
    element: CoveragesScreenNewScreen,
  },
  // ItemsScreen
  {
    name: "ItemsScreenListScreen",
    path: "items",
    element: ItemsScreenListScreen,
  },
  {
    name: "ItemsScreenDetailScreen",
    path: "items/:item_id",
    element: ItemsScreenDetailScreen,
  },
  {
    name: "ItemsScreenEditScreen",
    path: "items/:item_id/edit",
    element: ItemsScreenEditScreen,
  },
  {
    name: "ItemsScreenNewScreen",
    path: "items/new",
    element: ItemsScreenNewScreen,
  },
  // LocationsScreen
  {
    name: "LocationsScreenListScreen",
    path: "locations",
    element: LocationsScreenListScreen,
  },
  {
    name: "LocationsScreenEditScreen",
    path: "locations/:location_id/edit",
    element: LocationsScreenEditScreen,
  },
  {
    name: "LocationsScreenNewScreen",
    path: "locations/new",
    element: LocationsScreenNewScreen,
  },
  // OrdersScreen
  {
    name: "OrdersScreenListScreen",
    path: "orders",
    element: OrdersScreenListScreen,
  },
  {
    name: "OrdersScreenDetailScreen",
    path: "orders/:order_id",
    element: OrdersScreenDetailScreen,
  },
  {
    name: "OrdersScreenNewScreen",
    path: "orders/new",
    element: OrdersScreenNewScreen,
  },
  // ReportsScreen
  {
    name: "ReportsScreenMainScreen",
    path: "reports",
    element: ReportsScreenMainScreen,
  },
  // RoutesScreen
  {
    name: "RoutesScreenListScreen",
    path: "routes",
    element: RoutesScreenListScreen,
  },
  {
    name: "RoutesScreenDetailScreen",
    path: "routes/:route_id",
    element: RoutesScreenDetailScreen,
  },
  {
    name: "RoutesScreenEditScreen",
    path: "routes/:route_id/edit",
    element: RoutesScreenEditScreen,
  },
  {
    name: "RoutesScreenNewScreen",
    path: "routes/new",
    element: RoutesScreenNewScreen,
  },
  // ScheduleScreen
  {
    name: "ScheduleScreenListScreen",
    path: "schedule",
    element: ScheduleScreenListScreen,
  },
  {
    name: "ScheduleScreenDetailScreen",
    path: "schedule/:schedule_id",
    element: ScheduleScreenDetailScreen,
  },
  {
    name: "ScheduleScreenEditScreen",
    path: "schedule/:schedule_id/edit",
    element: ScheduleScreenEditScreen,
  },
  {
    name: "ScheduleScreenNewScreen",
    path: "schedule/new",
    element: ScheduleScreenNewScreen,
  },
  // SlotsScreen
  {
    name: "SlotsScreenListScreen",
    path: "slots",
    element: SlotsScreenListScreen,
  },
  // SettingsScreen
  {
    name: "SettingsScreenMainScreen",
    path: "settings",
    element: SettingsScreenMainScreen,
  },
  {
    name: "SettingsScreenSafetyScreen",
    path: "settings/safety",
    element: SettingsScreenSafetyScreen,
  },
  {
    name: "SourcesScreenManageScreen",
    path: "settings/sources",
    element: SourcesScreenManageScreen,
  },
  // SgsScreen
  {
    name: "SgsScreenListScreen",
    path: "sgs",
    element: SgsScreenListScreen,
  },
  {
    name: "SgsScreenDetailScreen",
    path: "sgs/:sg_id",
    element: SgsScreenDetailScreen,
  },
  // SourcesScreen
  {
    name: "SourcesScreenListScreen",
    path: "sources",
    element: SourcesScreenListScreen,
  },
  {
    name: "SourcesScreenDetailScreen",
    path: "sources/:source_id",
    element: SourcesScreenDetailScreen,
  },
  {
    name: "SourcesScreenEditScreen",
    path: "sources/:source_id/edit",
    element: SourcesScreenEditScreen,
  },
  {
    name: "SourcesScreenNewScreen",
    path: "sources/new",
    element: SourcesScreenNewScreen,
  },
  // StockScreen
  {
    name: "StockScreenListScreen",
    path: "stock",
    element: StockScreenListScreen,
  },
  {
    name: "StockScreenDetailScreen",
    path: "stock/:stock_id",
    element: StockScreenDetailScreen,
  },
  {
    name: "StockScreenNewScreen",
    path: "stock/new",
    element: StockScreenNewScreen,
  },
  // BusinessScreen
  {
    name: "BusinessScreenMainScreen",
    path: "business",
    element: BusinessScreenMainScreen,
  },
  // Dashboard
  {
    name: "HomeScreen",
    path: "dashboard",
    element: HomeScreen,
  },
]

export default listRoutes
