// import { ToggleSwitch } from "flowbite-react"
import st from "components/Custom/LabelSwitch/LabelSwitch.module.css"
import { useRef } from "react"

interface ISwitch {
  checked: boolean
  onChange: () => void
  label: string
}

const Switch = ({ checked, onChange, label }: ISwitch) => {
  const ref_switch: any = useRef(null)
  return (
    <div className="flex flex-row items-center justify-start gap-2">
      <label className={st["label_switch__switch"]}>
        <input ref={ref_switch} type="checkbox" checked={checked} onChange={onChange} />
        <span className={`${st["label_switch__slider"]} ${st["label_switch__round"]}`}></span>
      </label>
      <p onClick={() => ref_switch?.current?.click()} className="cursor-pointer">
        {label}
      </p>
    </div>
  )
}

export default Switch
