const route_values = {
  id: "",
  status: false,
  source: {
    type: "Source",
    data: {},
  },
  target: {
    type: "Location",
    data: {},
  },
}

export default route_values
