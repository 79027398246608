import { Breadcrumb, Paginator, Table, TitleScreen } from "components/Core"
import ButtonListNavigation from "components/ButtonListNavigation"
import SearchBarFilter from "components/SearchBarFilter"
import { get_data_table_areas } from "helpers/data_tables"
import usePaginationTable from "hooks/usePaginationTable"
import useSearchOSRM from "hooks/useSearchOSRM"
import { useEffect } from "react"

const ListScreen = () => {
  const { loading_app, data, set_data } = useSearchOSRM({ entity: "areas" })
  const { current_data, current_page, handle_page_change, handle_per_page, per_page, total_records, total_pages } =
    usePaginationTable({
      data,
    })

  useEffect(() => {
    //set_data({records: [{ id: "TEST" }]})
    set_data({records: []})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const data_table_areas = get_data_table_areas(current_data)

  // useEffect(() => {
  //   search_osrm_action({ from: per_page * current_page, size: per_page })
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [current_page, per_page])

  return (
    <div className="w-full h-full flex flex-col items-center justify-start">
      <Breadcrumb data={[{ name: "Listado de zonas", href: "/areas" }]} />
      <div className="w-full flex flex-col justify-center items-center py-4 px-6">
        <TitleScreen title="listado de zonas" />
        {/* <p>areasListScreen</p> */}
      </div>
      <div className="w-full flex flex-col gap-2">
        <div className="w-full flex flex-col lg:flex-row justify-between items-end lg:items-center gap-4 px-6">
          <SearchBarFilter />
          <ButtonListNavigation label="Crear zona" route="/areas/new" />
        </div>
        <div className="w-full flex flex-col gap-2 px-6">
          <Paginator
            current_page={current_page}
            handle_page_change={handle_page_change}
            per_page={per_page}
            total_records={total_records}
            total_pages={total_pages}
            handle_per_page={handle_per_page}
          />
          <div className="w-full overflow-x-auto">
            <Table>
              <Table.Head>
                <Table.HeadCell>id</Table.HeadCell>
              </Table.Head>
              <Table.Body>
                {!loading_app && data_table_areas?.length ? (
                  data_table_areas.map((area: any, i: number) => (
                    <Table.Row key={i}>
                      <Table.Cell mainCol>
                        <a href={`/areas/${area?.id}`} className="text-[#F9004D] underline">
                          {area?.id}
                        </a>
                      </Table.Cell>
                    </Table.Row>
                  ))
                ) : (
                  <Table.Row>
                    <Table.Cell>{loading_app ? "Espere..." : "Sin resultados"}</Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListScreen
