import { useContext, useState } from "react"
import { post_middleware_osrm_search } from "services/middleware_services"
import useCancelToken from "hooks/useCancelToken"
import { AppContext } from "store/context/AppContext"
import AuthContext from "store/context/PublicContext"
import useAuthorizer from "overrides/theme/entel/base/hooks/useAuthorizer"
import Swal from "sweetalert2"

const useSearchMiddlewareOSRM = ({ entity }: any) => {
  const [data, set_data] = useState<any>()
  const { newCancelToken, isCancel } = useCancelToken()
  const { logout_user_action } = useAuthorizer()

  const {
    user: { token },
  } = useContext(AuthContext)

  const { loading_app, set_loading_app } = useContext(AppContext)

  const search_osrm_action = async (body: any) => {
    try {
      set_loading_app(true)
      set_data(null)
      let {
        data: { message },
      } = await post_middleware_osrm_search(entity, body, token, newCancelToken())
      set_data(message)
      set_loading_app(false)
    } catch (error: any) {
      set_loading_app(false)
      if (isCancel(error)) return
      Swal.fire({
        title: "Error",
        text: error?.response?.data?.message,
        icon: "error",
      })
      if ([401].includes(error?.response?.status)) return logout_user_action()
    }
  }

  const action_search_osrm_action = async (_entity: string, _body: any) => {
    try {
      const res = await post_middleware_osrm_search(_entity, _body, token, newCancelToken())
      return res
    } catch (error: any) {
      if (isCancel(error)) return
      Swal.fire({
        title: "Error",
        text: error?.response?.data?.message,
        icon: "error",
      })
      if ([401].includes(error?.response?.status)) return logout_user_action()
    }
  }

  return {
    data,
    set_data,
    loading_app,
    set_loading_app,
    search_osrm_action,
    action_search_osrm_action,
  }
}

export default useSearchMiddlewareOSRM
