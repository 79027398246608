import * as yup from "yup"

const schemaValidationStock = yup.object().shape({
  id: yup.string().required(),
  name: yup.string().required(),
  sources: yup
    .array(
      yup.object().shape({
        id: yup.string().required(),
        name: yup.string().required(),
      }),
    )
    .min(1, "you-must-enter-at-least-1-source")
    .required("required-field")
    .typeError("you-must-enter-at-least-one-value"),
  safety: yup.object().shape({
    type: yup.string().required(),
    value: yup.number().min(0).required(),
  }),
})

export default schemaValidationStock
