import { useState, useCallback, useEffect } from "react"
import { Breadcrumb, TitleScreen } from "components/Core"
import axios from "axios"
import useForm from "hooks/useForm"
//import { APIProvider, Map } from "@vis.gl/react-google-maps"
import schemaValidationBusiness from "utils/yup/business_schema"
import InitialForm from "components/FormEntities/FormAreas/InitialForm"
import FinalForm from "components/FormEntities/FormAreas/FinalForm"
import classNames from "classnames"
import usePreRender from "hooks/usePreRender"
import { useNavigate } from "react-router-dom"
import useSearchMiddlewareOSRM from "hooks/useSearchMiddlewareOSRM"
import { SHIPPING_TYPES } from "constants/FORM_CONSTANTS"
import useCombinedCoverageBFF from "hooks/bff_entities/useCombinedCoverageBFF"
import Swal from "sweetalert2"
// import useCombinedCoverageBFF from "hooks/bff_entities/useCombinedCoverageBFF"

// Types for Google Maps
interface LatLng {
  lat: number
  lng: number
}

interface Region {
  name: string
  coordinates: google.maps.LatLngLiteral[] // Coordinates of the polygon
}

interface PolygonCoordinates {
  path: LatLng[]
}

const NewScreen = () => {
  const navigate = useNavigate()
  const { action_search_osrm_action } = useSearchMiddlewareOSRM({})
  const { post_create_combined_coverages_action } = useCombinedCoverageBFF()
  // const { post_create_combined_coverages_action } = useCombinedCoverageBFF()
  const { _color } = usePreRender()
  const tk = "NjMxZjZkNjctNGE2Yy00YjFkLTgzMjAtMzYyNzQ5YWMyZTUx"
  const url_oma = "https://womdev-oma.omnixsystem.com/v3/monitor/"
  const [mode] = useState("view")
  const [list_region, set_list_region] = useState([])
  const [list_commune, set_list_commune] = useState([])
  const [current_region, set_current_region]: any = useState(null)
  const [current_province, set_current_province]: any = useState(null)
  const [current_commune, set_current_commune]: any = useState(null)
  const [list_zones_inclusion, set_list_zones_inclusion]: any = useState([])
  const [list_zones_exclusion, set_list_zones_exclusion]: any = useState([])
  const [coveragesAreas, set_coveragesAreas] = useState<any>([])
  const [polygons, setPolygons] = useState<PolygonCoordinates[]>([])
  const [drawingManager, setDrawingManager] = useState<google.maps.drawing.DrawingManager | null>(null)
  const [map, setMap]: any = useState<google.maps.Map | null>(null)
  const [geoJsonData, setGeoJsonData] = useState(null)
  const [step, setStep] = useState(1)
  const [body_to_send, set_body_to_send] = useState<any>({})
  const [zone_dinamic, set_zone_dinamic] = useState(false)
  // const [listconfig_zone, set_config_zone] = useState(false)
  const [schedule_dinamic_name, set_schedule_dinamic_name]: any = useState("")

  // Callback when a polygon is drawn
  const onPolygonComplete = useCallback((polygon: google.maps.Polygon) => {
    const path = polygon.getPath().getArray()
    const coordinates: LatLng[] = path.map((coord) => ({
      lat: coord.lat(),
      lng: coord.lng(),
    }))

    setPolygons((prevPolygons) => [...prevPolygons, { path: coordinates }])
  }, [])

  const { control, reset } = useForm({
    schema: schemaValidationBusiness,
  })

  const load_geoson = async (region_id: any, commune_id: any) => {
    try {
      const response: any = await axios.get(`/assets/geojson/communes/000${region_id}-${commune_id}.geojson`)
      const regionData = response.data.features[0]

      setGeoJsonData(regionData)

      console.log(geoJsonData)

      const region: Region = {
        name: regionData.properties.codigo_comuna,
        coordinates: regionData.geometry.coordinates[0].map((coord: any) => ({
          lat: coord[1],
          lng: coord[0],
        })),
      }

      const _lng = Array.isArray(regionData.geometry.coordinates[0][0][0])
        ? regionData.geometry.coordinates[0][0][0][0]
        : regionData.geometry.coordinates[0][0][0]
      const _lat = Array.isArray(regionData.geometry.coordinates[0][0][1])
        ? regionData.geometry.coordinates[0][0][1][1]
        : regionData.geometry.coordinates[0][0][1]
      console.log(_lat, _lng)
      console.log(region)
      if (regionData) {
        map.data.forEach((feature: any) => map.data.remove(feature))
        map.data.addGeoJson(regionData)
        map.data.setStyle({
          fillColor: "green",
          strokeWeight: 0.3,
        })
        map.setCenter({ lat: _lat, lng: _lng })
        map.setZoom(11)
      }
    } catch (error) {
      console.error("Error loading region data", error)
    }
  }

  const map_update = async (val: any) => {
    const _commune: any = list_commune.filter((commune: any) => commune.id === val)
    set_current_commune(_commune[0])
    await fill_list_provice(_commune[0]?.label)
    if (map) {
      load_geoson(current_region?.id, _commune[0]?.id)
    }
  }

  const fill_list_commune = async (val: any) => {
    const _region: any = list_region.filter((region: any) => region.id === val)
    if (!!_region.length) {
      let res: any = await fetch(url_oma + "regions?country=CHILE&region=" + _region[0].label, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + tk,
        },
      })
      res = await res.json()
      if (res.code === 200 && !!res.message.length) {
        let _res = res.message[0].communes.reduce((current: any, next: any) => {
          return [
            ...current,
            {
              label: next.name,
              id: next.code,
              value: next.code,
              lat: next.coordinates.latitude,
              lng: next.coordinates.longitude,
            },
          ]
        }, [])
        set_list_commune(_res)
        set_current_region(_region[0])
        if (map) {
          map.setCenter({ lat: Number(_region[0].lat), lng: Number(_region[0].lng) })
        }
      }
    }
  }

  const fill_list_region = async () => {
    if (!list_region.length) {
      let res: any = await fetch(url_oma + "regions?country=CHILE", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + tk,
        },
      })
      res = await res.json()

      if (res.code === 200 && !!res.message) {
        let _res = res.message.reduce((current: any, next: any) => {
          return [
            ...current,
            {
              label: next.name,
              id: next.code,
              value: next.code,
              lat: next.coordinates.latitude,
              lng: next.coordinates.longitude,
            },
          ]
        }, [])
        set_list_region(_res)
      }
    }
  }

  const fill_list_provice = async (comm: string) => {
    const res: any = await action_search_osrm_action("locations", {
      from: 0,
      size: 100,
      filters: {
        criteria0: "CHILE",
        criteria1: current_region?.label,
        criteria3: comm,
      },
    })
    set_current_province(res?.data?.message?.records[0]?.criteria2)
    console.log("PROVINCE", res?.data?.message?.records[0]?.criteria2)
  }

  const commune_draw_full_exclution_zone = () => {
    if (!current_commune) {
      console.log(current_commune, current_region)
    }
  }

  useEffect(() => {
    fill_list_region()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handle_back_step = () => {
    if (step === 1) return navigate(-1)
    setStep(1)
  }

  const handle_next_step = () => {
    console.log(list_zones_inclusion)
    if (step === 1) {
      setStep(2)
      return
    }
    on_submit()
  }

  const on_submit = async () => {
    let body = {
      sources: body_to_send?.sources ?? [],
      couriers: body_to_send?.couriers?.length ? body_to_send?.couriers?.map((_cou: string) => ({ id: _cou })) : [],
      location: {
        criteria0: "CHILE",
        criteria1: current_region?.label,
        criteria2: current_province,
        criteria3: current_commune?.label,
      },
      workingTime: body_to_send?.workingTime ?? [],
      deliveryDays: body_to_send?.deliveryDays ?? [],
      combinations: body_to_send?.combinations?.map((_comb: any) => ({
        shippingType: SHIPPING_TYPES.find((_st: any) => _st?.label === _comb?.shippingType)?.value,
        deliveryType: _comb?.deliveryType,
        channel: _comb?.channel,
        leadtime: {
          value: _comb?.leadTime?.value,
          unit: _comb?.leadTime?.unit,
        },
        cost: {},
        status: true,
      })),
      coveragesAreas,
    }

    console.log("body_to_send", body)

    const res: any = await post_create_combined_coverages_action({ body })
    console.log("res", res)
    if (res?.data?.code === 200) {
      Swal.fire({
        title: "Hecho",
        text: "Zona creada",
        icon: "success",
      })
      navigate(`/areas`)
    } else {
      Swal.fire({
        title: "Error",
        text: JSON.stringify(res?.data?.message),
        icon: "error",
      })
    }
  }

  return (
    <div className="w-full h-full flex flex-col items-start justify-start pb-10">
      <Breadcrumb
        data={[
          { name: "Listado de zonas", href: "/areas" },
          { name: "Creación de zona", href: "/areas/new" },
        ]}
      />
      <div className="w-full flex flex-col justify-center items-center pt-4 px-6">
        <TitleScreen title="creación de zona" />
      </div>
      <form className="w-full flex flex-col py-2 px-4">
        <div className="w-full flex flex-row items-center justify-end lg:pb-6 py-2">
          <div className="flex flex-row gap-2 font-semibold">
            <button
              type="button"
              onClick={handle_back_step}
              className={classNames(
                "w-full lg:w-48 p-2 text-sm text-md border hover:opacity-80 rounded-md shadow-sm hover:shadow-md transform transition duration-500 hover:scale-105",
                `border_${_color}`,
                `text_${_color}`,
              )}
            >
              {step === 1 ? "Cancelar" : "Atrás"}
            </button>
            <button
              type="button"
              onClick={handle_next_step}
              disabled={!current_commune || !coveragesAreas.length}
              className={classNames(
                "w-full lg:w-48 p-2 text-sm text-md hover:opacity-80 text-white rounded-md shadow-sm hover:shadow-md transform transition duration-500 hover:scale-105",
                `bg_${_color}`,
              )}
            >
              {step === 1 ? "Siguiente" : "Guardar"}
            </button>
          </div>
        </div>
        {step === 1 ? (
          <InitialForm
            control={control}
            fill_list_commune={fill_list_commune}
            list_commune={list_commune}
            list_region={list_region}
            map_update={map_update}
            onPolygonComplete={onPolygonComplete}
            mode={mode}
            polygons={polygons}
            setDrawingManager={setDrawingManager}
            setMap={setMap}
            map={map}
            drawingManager={drawingManager}
            commune_draw_full_exclution_zone={commune_draw_full_exclution_zone}
            current_commune={current_commune}
            current_region={current_region}
            set_coveragesAreas={set_coveragesAreas}
            set_list_zones_inclusion={set_list_zones_inclusion}
            list_zones_inclusion={list_zones_inclusion}
            set_list_zones_exclusion={set_list_zones_exclusion}
            list_zones_exclusion={list_zones_exclusion}
            set_zone_dinamic={set_zone_dinamic}
            zone_dinamic={zone_dinamic}
            schedule_dinamic_name={schedule_dinamic_name}
            set_schedule_dinamic_name={set_schedule_dinamic_name}
            setPolygons={setPolygons}
            body_to_send={body_to_send}
            set_body_to_send={set_body_to_send}
            reset={reset}
          />
        ) : (
          <FinalForm
            body_to_send={body_to_send}
            set_body_to_send={set_body_to_send}
            current_commune={current_commune}
            list_zones_inclusion={list_zones_inclusion}
            list_zones_exclusion={list_zones_exclusion}
          />
        )}
      </form>
    </div>
  )
}

export default NewScreen
