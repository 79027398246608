import usePreRender from 'hooks/usePreRender';
import { SourceSchedule } from 'interfaces/ISlots';
import React from 'react';
import { cn } from 'utils/utils';

interface DeliveryMethodSelectProps {
  list: SourceSchedule[];
  value: number;
  onChange: (value: number) => void;
}

export const DeliveryMethodSelect: React.FC<DeliveryMethodSelectProps> = ({
  list,
  value,
  onChange,
}) => {
  const { _color } = usePreRender()
  return (
    <div className="inline-flex rounded-lg border border-gray-200 p-1 bg-gray-50">
      {list?.length > 0 && list.map((method, index) => (
        <button
          key={index}
          onClick={() => onChange(index)}
          className={cn(
            "px-4 py-2 text-sm font-medium rounded-md transition-colors",
            value === index
              ? `bg-white text_${_color} shadow-sm`
              : "text-gray-500 hover:text-gray-700"
          )}
        >
          {method.deliveryType + '-' + method.shippingType}
        </button>
      ))}
    </div>
  );
}