import { AddButton, FormSection, Select, Switch, TextField } from "components/Core"
import { Controller } from "react-hook-form"
import { item_type } from "./item.config"
import { Items } from "components/DinamicFormSections"
import { useEffect, useState } from "react"
import useSearchMiddlewareOSRM from "hooks/useSearchMiddlewareOSRM"
import Swal from "sweetalert2"

interface IFormItem {
  control: any
  errors: any
  watch: any
  custom_field_form: any
}

const FormItem = ({ control, errors, watch, custom_field_form }: IFormItem) => {
  const [delivery_types, set_delivery_types] = useState<Array<string>>([])
  const { data: data_schedules, search_osrm_action: search_osrm_action_schedules } = useSearchMiddlewareOSRM({
    entity: "schedules",
  })

  useEffect(() => {
    search_osrm_action_schedules({ from: 0, size: 1000, fields: ["id", "deliveryType"] })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const get_delivery_types_action = async () => {
      try {
        let schedule_from_req = data_schedules?.records
        set_delivery_types(schedule_from_req?.map((sch: any) => sch?.deliveryType))
      } catch (error: any) {
        Swal.fire({
          title: "Error",
          text: JSON.stringify(error?.response?.data?.message),
          icon: "error",
        })
      }
    }
    get_delivery_types_action()
    // eslint-disable-next-line
  }, [data_schedules])

  const add_new_custom = () => custom_field_form.append({ type: "string", key: "", value: "" })

  const validationsSectionsErrors = {
    1: Boolean(["name", "sku", "storePickup", "enabled"].some((key: string) => Object.keys(errors).includes(key))),
    2: Boolean(["categories", "barcodes"].some((key: string) => Object.keys(errors).includes(key))),
    3: Boolean(["logisticAttributes"].some((key: string) => Object.keys(errors).includes(key))),
    4: Boolean(["preparationTime"].some((key: string) => Object.keys(errors).includes(key))),
    5: Boolean(["custom"].some((key: string) => Object.keys(errors).includes(key))),
  }

  return (
    <div className="w-full flex flex-col items-center gap-4">
      <FormSection
        title="Información general"
        helperText="Información general"
        number={1}
        anyError={validationsSectionsErrors[1]}
      >
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          <Controller
            name="sku"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => <TextField label="SKU" onChange={onChange} value={value} />}
          />
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => <TextField label="Nombre" onChange={onChange} value={value} />}
          />
          <div className="w-full flex flex-col lg:flex-row gap-4">
            <Controller
              name="safety"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextField label="Stock de seguridad" type="number" onChange={onChange} value={value} />
              )}
            />
            <Controller
              name="type"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <Select options={item_type} label="Tipo" onChange={onChange} value={value} />
              )}
            />
          </div>
          <div className="flex flex-col lg:flex-row gap-4">
            <Controller
              name="storePickup"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <Switch label="Retiro en tienda" onChange={onChange} checked={value} />
              )}
            />
            <Controller
              name="enabled"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <Switch label="Artículo habilitado" onChange={onChange} checked={value} />
              )}
            />
          </div>
        </div>
      </FormSection>
      <FormSection
        title="Detalle de artículo"
        helperText="Detalle de artículo"
        number={2}
        anyError={validationsSectionsErrors[2]}
      >
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          <Controller
            name="categories"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <TextField label="Categorías" onChange={onChange} value={value} />
            )}
          />
          <Controller
            name="barcodes"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <TextField label="Código de barras" onChange={onChange} value={value} />
            )}
          />
        </div>
      </FormSection>
      <FormSection
        title="Atributos logísticos"
        helperText="Atributos logísticos"
        number={3}
        anyError={validationsSectionsErrors[3]}
      >
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          <Controller
            name="logisticAttributes.size"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => <TextField label="Tamaño" onChange={onChange} value={value} />}
          />
          <Controller
            name="logisticAttributes.volume"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => <TextField label="Volumen" type="number" onChange={onChange} value={value} />}
          />
        </div>
      </FormSection>
      <FormSection
        title="Tiempo de preparación"
        helperText="Tiempo de preparación"
        number={4}
        anyError={validationsSectionsErrors[4]}
      >
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          {Boolean(delivery_types?.length) ? (
            delivery_types?.map((del: string, _i: number) => (
              <Controller
                key={_i}
                name={`preparationTime[${del}]`}
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <TextField label={del} type="number" onChange={onChange} value={value} />
                )}
              />
            ))
          ) : (
            <Controller
              name="preparationTime.default"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextField label="Default" type="number" onChange={onChange} value={value} />
              )}
            />
          )}
        </div>
      </FormSection>
      <FormSection title="Custom" helperText="Custom" number={5} anyError={validationsSectionsErrors[5]}>
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          {custom_field_form.fields.map((customItem: any, i: number) => {
            return (
              <Items.CustomFields
                key={customItem.id}
                index={i}
                custom={custom_field_form}
                control={control}
                errors={errors}
                watch={watch}
              />
            )
          })}
          <div className="flex flex-row items-center justify-start">
            <AddButton onClick={add_new_custom} />
          </div>
        </div>
      </FormSection>
    </div>
  )
}

export default FormItem
