import cn from "classnames"
import { useCallback, useState } from "react"
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io"
import usePreRender from "hooks/usePreRender"
import { JsonViewer } from "components/Core"
interface ICollapseWithDetail {
  title: string
  details: any
}

const CollapseWithDetail = ({ title, details }: ICollapseWithDetail) => {
  const { _color } = usePreRender()
  const [open_collapse, set_open_collapse] = useState(false)

  const handle_collapse = useCallback(() => {
    set_open_collapse(!open_collapse)
  }, [open_collapse, set_open_collapse])

  const conditional_arrow_styles = {
    [`text_${_color}`]: Boolean(open_collapse),
  }

  // Translation functions
  const translateKey = (key: string, _: any, path: string[]): string => {
    const translations: Record<string, string> = {
      creationDate: "Fecha de creación",
      from: "Desde",
      to: "Hasta",
      daysOfWeek: "Días de la semana",
      productType: "Tipo de producto"
    }
    return translations[key] || key
  }

  const translateValue = (key: string, value: any, path: string[]): string => {
    // If the parent is daysOfWeek, translate numbers to day names
    if (path.includes("daysOfWeek") || path.includes("deliveryDays") || path.includes("pickupDays")) {
      const days = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"]
      return days[value - 1] || String(value)
    }

    // Format dates
    if (typeof value === "string" && value.match(/^\d{4}-\d{2}-\d{2}$/)) {
      return new Date(value).toLocaleDateString("es-ES", {
        year: "numeric",
        month: "long",
        day: "numeric",
      })
    }

    return String(value)
  }

  return (
    <div className="w-full h-auto flex flex-col justify-center items-center bg-white rounded-xl">
      <div
        className={cn("w-full h-auto flex flex-row items-center justify-between cursor-pointer rounded-xl border p-4", {
          "rounded-b-none": Boolean(open_collapse),
        })}
        onClick={handle_collapse}
      >
        <h4 className="text-lg font-medium">{title}</h4>
        {open_collapse ? (
          <IoIosArrowDown className={cn("text-lg font-semibold", conditional_arrow_styles)} />
        ) : (
          <IoIosArrowForward className={cn("text-lg font-semibold", conditional_arrow_styles)} />
        )}
      </div>
      {open_collapse ? (
        <div className="w-full flex border border-t-0 rounded-b-xl">
          <div className="w-full flex flex-col word_break_container">
            {details ? (
              // <json-viewer data={JSON.stringify(details)}></json-viewer>
              <JsonViewer data={details} translateKey={translateKey} translateValue={translateValue} />
            ) : (
              <div className="text-center p-2">
                <p>No hay Información para mostrar!</p>
              </div>
            )}

            {/* {Object.entries(details).map(([key, val]: any, i: number) => (
              <div key={i} className="w-full border-t p-4">
                <p className="text-[#4C4C4C] text-sm">
                  <span className="font-semibold">{key}:</span> {val}
                </p>
              </div>
            ))} */}
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default CollapseWithDetail
