import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { CiTrash } from "react-icons/ci";
import { generateTimeOptions, cn } from 'utils/utils';

interface TimeSlotRowProps {
  index: number;
  register: UseFormRegister<any>;
  onDelete: () => void;
  slotId: string;
}

export const TimeSlotRow: React.FC<TimeSlotRowProps> = ({
  index,
  register,
  onDelete,
  slotId,
}) => {
  const timeOptions = generateTimeOptions();

  const handleCapacityInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Allow: backspace, delete, tab, escape, enter, decimal point
    if ([46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A, Ctrl+C, Ctrl+V, Ctrl+X
      (e.keyCode >= 35 && e.keyCode <= 39) ||
      // Allow: home, end, left, right
      (e.ctrlKey === true && [65, 67, 86, 88].indexOf(e.keyCode) !== -1)) {
      return;
    }
    // Ensure that it is a number and stop the keypress if not
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) &&
      (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }
  };

  return (
    <div className={cn(
      "grid items-center gap-3",
      "grid-cols-[240px_240px_200px_250px_150px_150px]"
    )}>
      <select
        {...register(`${slotId}.from`)}
        className="materialize-select text-sm"
      >
        {timeOptions.map((time) => (
          <option key={time} value={time}>
            {time}
          </option>
        ))}
      </select>

      <select
        {...register(`${slotId}.to`)}
        className="materialize-select text-sm"
      >
        {timeOptions.map((time) => (
          <option key={time} value={time}>
            {time}
          </option>
        ))}
      </select>

      <input
        type="number"
        min="0"
        max="9999"
        onKeyDown={handleCapacityInput}
        {...register(`${slotId}.capacity`, {
          valueAsNumber: true,
          validate: {
            isNumber: (value) => !isNaN(value) || "Must be a number",
            isPositive: (value) => value >= 0 || "Must be positive",
            isInteger: (value) => Number.isInteger(value) || "Must be an integer"
          }
        })}
        className="materialize-input text-sm"
      />

      <input
        type="text"
        {...register(`${slotId}.label`)}
        placeholder={`Slot ${index + 1}`}
        className="materialize-input text-sm"
      />

      <div className="flex items-center justify-center gap-3 px-2">
        <label className="relative inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            {...register(`${slotId}.status`)}
            className="sr-only peer"
          />
          <div className= {cn(
                 "w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-indigo-200 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-indigo-600",
          )}></div>

        </label>
      </div>

      <div className="flex items-center justify-center gap-3 px-2">
        <button
          type="button"
          onClick={onDelete}
          className="p-1.5 text-gray-400 hover:text-red-600 rounded-lg hover:bg-red-50 transition-colors"
        >
          <CiTrash size={16} />
        </button>
      </div>
    </div>
  );
};