import React, { useEffect, useRef, useState } from "react"
import { IoMdClose } from "react-icons/io"
import { IoSearch } from "react-icons/io5"
import { Controller } from "react-hook-form"
import clsx from "classnames"
import { Option, SearchableSelectProps } from "./types"

const SearchableSelect: React.FC<SearchableSelectProps> = ({
  options = [],
  setOptions,
  isMulti = false,
  isLoading = false,
  placeholder = "Search...",
  error,
  onSearch,
  onChange,
  value,
  name,
  control,
  seeLabelWithId,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState("")
  const dropdownRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => document.removeEventListener("mousedown", handleClickOutside)
  }, [])

  const handleSearch = async (query: string) => {
    setSearchQuery(query)
    await onSearch(query)
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const selectedOptions = isMulti ? (field.value as Option[]) || [] : field.value ? [field.value as Option] : []

        const handleSelect = (option: Option) => {
          let newValue: Option | Option[] | null = null

          if (isMulti) {
            const isSelected = selectedOptions.some((item) => item.id === option.id)
            const newOptions = isSelected
              ? selectedOptions.filter((item) => item.id !== option.id)
              : [...selectedOptions, option]
            newValue = newOptions
          } else {
            newValue = option
            setOptions && setOptions([])
            setIsOpen(false)
          }

          field.onChange(newValue)
          onChange?.(newValue)
        }

        const removeOption = (optionId: string) => {
          const newValue = isMulti ? selectedOptions.filter((item) => item.id !== optionId) : null
          field.onChange(newValue)
          onChange?.(newValue)
        }

        return (
          <div className="relative w-full" ref={dropdownRef}>
            {/* Selected Options Chips */}
            {isMulti && selectedOptions.length > 0 && (
              <div className="flex flex-wrap gap-2 mb-2">
                {selectedOptions.map((option) => (
                  <div
                    key={option.id}
                    className="flex items-center gap-1 px-2 py-1 text-sm bg-blue-100 text-blue-800 rounded-full"
                  >
                    <span>{option.name}</span>
                    <button
                      type="button"
                      onClick={() => removeOption(option.id)}
                      className="p-0.5 hover:bg-blue-200 rounded-full"
                    >
                      <IoMdClose className="w-4 h-4" />
                    </button>
                  </div>
                ))}
              </div>
            )}

            {/* Search Input */}
            <div className="relative">
              <input
                ref={inputRef}
                type="text"
                className={clsx("w-full px-4 py-2 pr-10 border rounded-lg focus:outline-none focus:ring-2", {
                  "border-red-300 focus:ring-red-200": error,
                  "border-gray-300 focus:ring-blue-200": !error,
                })}
                placeholder={placeholder}
                value={searchQuery}
                onChange={(e) => handleSearch(e.target.value)}
                onFocus={() => setIsOpen(true)}
              />
              <div className="absolute right-3 top-1/2 -translate-y-1/2">
                {isLoading ? (
                  <div className="w-5 h-5 border-2 border-gray-300 border-t-blue-500 rounded-full animate-spin" />
                ) : (
                  <IoSearch className="w-5 h-5 text-gray-400" />
                )}
              </div>
            </div>

            {/* Error Message */}
            {error && <p className="mt-1 text-sm text-red-500">{error}</p>}

            {/* Dropdown */}
            {isOpen && (
              <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg max-h-60 overflow-auto">
                {options.length === 0 ? (
                  <div className="px-4 py-2 text-sm text-gray-500">No se han encontrado opciones</div>
                ) : (
                  options.map((option) => (
                    <button
                      key={option.id}
                      type="button"
                      className={clsx("w-full px-4 py-2 text-left hover:bg-gray-100 text-sm", {
                        "bg-blue-50": selectedOptions.some((item) => item.id === option.id),
                      })}
                      onClick={() => handleSelect(option)}
                    >
                      {seeLabelWithId ? `${option.name} (${option.id})` : option.name}
                    </button>
                  ))
                )}
              </div>
            )}
          </div>
        )
      }}
    />
  )
}

export default SearchableSelect
