const item_values = {
  id: "",
  name: "",
  sku: "",
  type: "Default",
  safety: 0,
  storePickup: false,
  enabled: true,
  categories: "",
  barcodes: "",
  logisticAttributes: {},
  preparationTime: {
    default: "",
  },
  custom: [
    {
      key: "",
      type: "string",
      value: "",
    },
  ],
}

export default item_values
