import { Breadcrumb, ButtonGroup, TitleScreen } from "components/Core"
import useForm from "hooks/useForm"
import schemaValidationStock from "utils/yup/stock_schema"
import stock_values from "utils/forms_default/stock_values"
import { FormStock } from "components/FormEntities"
import useStockResources from "hooks/entities/useStockResources"
import { useNavigate } from "react-router-dom"
import { parsed_body_to_send } from "components/FormEntities/FormStock/stock.config"
import Swal from "sweetalert2"

const NewScreen = () => {
  const navigate = useNavigate()
  const { control, watch, errors, setValue, handleSubmit } = useForm({
    defaultValues: stock_values,
    schema: schemaValidationStock,
  })
  const { set_loading_app, post_create_stock_action } = useStockResources()

  const on_submit = async (data: any) => {
    try {
      set_loading_app(true)
      const body = parsed_body_to_send(data)
      let res: any = await post_create_stock_action({ body })
      if (res?.data?.code === 200) {
        Swal.fire({
          title: "Hecho",
          text: "Stock creado",
          icon: "success",
        })
        navigate(`/stock/${body.id}`)
      }
      set_loading_app(false)
    } catch (error: any) {
      Swal.fire({
        title: "Error",
        text: JSON.stringify(error?.response?.data?.message),
        icon: "error",
      })
      set_loading_app(false)
    }
  }
  console.log("errors", errors)

  return (
    <div className="w-full h-full flex flex-col items-start justify-start">
      <Breadcrumb
        data={[
          { name: "Listado de stock", href: "/stock" },
          { name: "Creación de stock", href: "/stock/new" },
        ]}
      />
      <div className="w-full flex flex-col justify-center items-center py-4 px-6">
        <TitleScreen title="creación de stock" />
        {/* <p>StockNewScreen</p> */}
      </div>
      <form className="w-full flex flex-col py-2 px-4 lg:px-10" onSubmit={handleSubmit(on_submit)}>
        <div className="w-full flex flex-row items-center justify-end lg:pb-10 py-2">
          <ButtonGroup />
        </div>
        <FormStock control={control} errors={errors} watch={watch} setValue={setValue} />
      </form>
    </div>
  )
}

export default NewScreen
