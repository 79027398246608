const channel_values = {
  id: "",
  name: "",
  stock: {
    id: "",
  },
  
}

export default channel_values
