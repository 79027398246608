import React, { useRef, useState } from "react"
import { IoChevronDownOutline } from "react-icons/io5"
import clsx from "classnames"
import { SelectOption } from "./SelectOption"
import { SelectedTag } from "./SelectedTag"
import { Option, SelectProps } from "./types"

const Select = React.forwardRef<HTMLDivElement, SelectProps>(
  (
    { options, multi = false, isLoading = false, label, className = "", error, value, onChange, disabled, ...props },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(false)
    const selectRef = useRef<HTMLDivElement>(null)

    const selectedOptions: any = multi
      ? options?.filter((option: any) => value?.includes(option.value))
      : options?.find((option: any) => option?.value === value)

    React.useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
          setIsOpen(false)
        }
      }

      document.addEventListener("mousedown", handleClickOutside)
      return () => document.removeEventListener("mousedown", handleClickOutside)
    }, [])

    const handleSelect = (option: Option) => {
      if (multi) {
        const newValue: any = value || []
        const index = newValue.indexOf(option.value)
        if (index === -1) {
          onChange?.([...newValue, option.value])
        } else {
          onChange?.(newValue.filter((v: string) => v !== option.value))
        }
      } else {
        onChange?.(option.value)
        setIsOpen(false)
      }
    }

    const removeOption = (optionValue: string) => {
      const newValue: any = (value || [])?.filter((v: string) => v !== optionValue)
      onChange?.(newValue)
    }

    return (
      <div className="w-full relative" ref={selectRef}>
        <div
          className={clsx(
            "w-full min-h-[42px] p-2 border rounded-lg cursor-pointer bg-white",
            "flex items-center justify-between gap-2",
            error ? "border-red-500" : "border-gray-300",
            "hover:border-gray-400 transition-colors",
            className,
          )}
          onClick={() => !disabled && setIsOpen(!isOpen)}
          {...props}
        >
          <div className="flex flex-wrap gap-1 flex-1">
            {multi ? (
              selectedOptions?.length > 0 ? (
                selectedOptions.map((option: any) => (
                  <SelectedTag key={option.value} option={option} onRemove={removeOption} />
                ))
              ) : (
                <span className="text-gray-500">{label}</span>
              )
            ) : selectedOptions ? (
              <span>{selectedOptions.label}</span>
            ) : (
              <span className="text-gray-500">{label}</span>
            )}
          </div>
          <IoChevronDownOutline
            size={20}
            className={clsx("transition-transform", isOpen ? "transform rotate-180" : "")}
          />
        </div>

        {error && <p className="text-red-500 text-sm mt-1">{error}</p>}

        {isOpen && (
          <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-auto">
            {isLoading ? (
              <div className="p-2 text-center text-gray-500">Cargando...</div>
            ) : options?.length === 0 ? (
              <div className="p-2 text-center text-gray-500">No hay opciones disponibles</div>
            ) : (
              options?.map((option: any) => (
                <SelectOption
                  key={option.value}
                  option={option}
                  isSelected={multi ? Boolean(value?.includes(option.value)) : Boolean(value === option.value)}
                  onSelect={handleSelect}
                />
              ))
            )}
          </div>
        )}
      </div>
    )
  },
)

export default Select
