import { Breadcrumb, StatusTimeline, TitleScreen } from "components/Core"
import CollapseWithDetail from "components/Core/Collapse/WithDetail"
// import DetailHistoryStatus from "../../components/DetailHistoryStatus"
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { format_date } from "helpers/datetime_helper"
import useSearchMiddlewareOSRM from "hooks/useSearchMiddlewareOSRM"
import translatesObj from "overrides/theme/entel/base/translates/translation.json"

const DetailScreen = () => {
  const statusses: any = translatesObj
  const [sg, set_data] = useState<any>()
  const { sg_id } = useParams()
  const { data: data_sgs, search_osrm_action: search_osrm_action_sgs } = useSearchMiddlewareOSRM({
    entity: "shipping_groups",
  })

  useEffect(() => {
    search_osrm_action_sgs({ from: 0, size: 1, filters: { id: sg_id } })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (Boolean(data_sgs?.records.length)) {
      const get_sg = async () => {
        let sg_from_req = data_sgs?.records[0]
        set_data(sg_from_req)
      }
      get_sg()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_sgs])

  const _docs = {
    _dte: sg?.dte?.url || null,
    _summary: sg?.enviameLabel?.length ? sg?.enviameLabel[0]?.summary : null,
    _labels: sg?.enviameLabel?.length ? sg?.enviameLabel[0]?.labels : null,
  }

  const courierName = sg?.logisticPlan?.route ? sg?.logisticPlan?.route[0]?.courier?.name : ""

  const currentState = sg?._omnixStateMachine?.currentState?.state?.name || ""

  return (
    <div className="w-full h-full flex flex-col items-start justify-start">
      <Breadcrumb
        data={[
          { name: "Listado de grupo de envíos", href: "/sgs" },
          { name: "Detalle de grupo de envíos", href: `/sgs/${sg_id}` },
        ]}
      />
      <div className="w-full flex flex-col justify-center items-center py-4 px-6">
        <TitleScreen title="Detalle de grupo de envíos" string_id={sg_id} />
        {/* <p>SourceDetailScreen</p> */}
      </div>
      <div className="w-full h-auto py-4 px-6">
        <StatusTimeline statuses={sg?._omnixStateMachine?.states} />
        {/* <DetailHistoryStatus statusses={sg?._omnixStateMachine?.states} /> */}
      </div>
      <div className="w-full flex flex-col lg:flex-row gap-6 py-4 px-6">
        <div className="w-full lg:w-1/2 flex flex-col gap-4">
          <div className="w-full flex flex-col gap-2">
            <h4 className="font-semibold text-[#4C4C4C]">Información</h4>
            <div className="grid grid-cols-2 grid-rows-5 bg-white rounded-xl border divide-x divide-y">
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Número de grupo de envíos</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{sg_id}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Número de orden</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{sg?.orderId}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Estado del grupo de envíos</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{statusses[currentState]?.title || currentState}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Canal</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{sg?.channel}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Portabilidad</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{sg?.orderType === "Portabilidad" ? "Si" : "No"}</p>
            </div>
          </div>
          <CollapseWithDetail title="Source" details={sg?.source} />
          <CollapseWithDetail title="Target" details={sg?.target} />
        </div>
        <div className="w-full lg:w-1/2 flex flex-col gap-4">
          <div className="w-full flex flex-col gap-2">
            <h4 className="font-semibold text-[#4C4C4C]">Detalle de grupo de envíos</h4>
            <div className="grid grid-cols-2 grid-rows-5 bg-white rounded-xl border divide-x divide-y">
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Fecha creación orden</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{format_date(sg?.orderCreationDate)}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Courier</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{courierName || ""}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Tipo de entrega</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{sg?.deliveryType}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">ID Canal de venta</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{sg?.salesChannelId}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Fecha estimada de entrega</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{format_date(sg?.estimatedDeliveryDate)}</p>
            </div>
          </div>
          <div className="w-full flex flex-col gap-2">
            <h4 className="font-semibold text-[#4C4C4C]">Adjuntos</h4>
            <div className="w-full flex flex-col bg-white rounded-xl border py-3">
              {Object.values(_docs).some((doc: string) => doc?.includes("http")) ? (
                <>
                  {_docs._dte ? (
                    <a
                      href={_docs._dte}
                      target="_blank"
                      rel="noreferrer"
                      className="text-sm text-[#F9004D] underline px-4 py-2"
                    >
                      DTE
                    </a>
                  ) : null}
                  {_docs._labels ? (
                    <a
                      href={_docs._labels}
                      target="_blank"
                      rel="noreferrer"
                      className="text-sm text-[#F9004D] underline px-4 py-2"
                    >
                      Etiqueta
                    </a>
                  ) : null}
                  {_docs._summary ? (
                    <a
                      href={_docs._summary}
                      target="_blank"
                      rel="noreferrer"
                      className="text-sm text-[#F9004D] underline px-4 py-2"
                    >
                      Manifiesto
                    </a>
                  ) : null}
                </>
              ) : (
                <p className="text-sm text-[#4C4C4C] px-4 py-2">No hay documentos</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DetailScreen
