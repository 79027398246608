import { FormSection, Select, TextField } from "components/Core"
import { Controller } from "react-hook-form"
import { useEffect, useState } from "react"
import useSearchMiddlewareOSRM from "hooks/useSearchMiddlewareOSRM"
import Swal from "sweetalert2"

interface IFormChannel {
  control: any
  errors: any
}

const FormChannel = ({ control, errors }: IFormChannel) => {
  const [stocks, set_stocks] = useState<Array<string>>([])
  const { data: data_stocks, search_osrm_action: search_osrm_action_stocks } = useSearchMiddlewareOSRM({
    entity: "stocks",
  })

  useEffect(() => {
    search_osrm_action_stocks({ from: 0, size: 1000, fields: ["id", "name"] })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const get_stocks_action = async () => {
      try {
        let stock_from_req = data_stocks?.records
        set_stocks(stock_from_req?.map((sch: any) => ({ label: sch?.name, value: sch?.id })))
      } catch (error: any) {
        Swal.fire({
          title: "Error",
          text: JSON.stringify(error?.response?.data?.message),
          icon: "error",
        })
      }
    }
    get_stocks_action()
    // eslint-disable-next-line
  }, [data_stocks])

  const validationsSectionsErrors = {
    1: Boolean(["name", "id", "stock"].some((key: string) => Object.keys(errors).includes(key))),
  }

  return (
    <div className="w-full flex flex-col items-center gap-4">
      <FormSection
        title="Información general"
        helperText="Información general"
        number={1}
        classNameContent="p-4"
        anyError={validationsSectionsErrors[1]}
      >
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          <div className="w-full flex flex-col lg:flex-row gap-4">
            <Controller
              name="id"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => <TextField label="Id" onChange={onChange} value={value} />}
            />
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextField label="Nombre del canal" onChange={onChange} value={value} />
              )}
            />
          </div>
          <Controller
            name="stock.id"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <Select label="Stock" options={stocks} onChange={onChange} value={value} />
            )}
          />
        </div>
      </FormSection>
    </div>
  )
}

export default FormChannel
