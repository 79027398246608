import React from 'react';
import { FaRegCopy  } from "react-icons/fa6"
import Swal from 'sweetalert2';

interface SourceCopyButtonProps {
  sourceId: string;
  sourceName: string;
  targetSourceId: string;
  targetSourceName: string;
  onCopy: (fromSourceId: string, toSourceId: string) => void;
}

export const SourceCopyButton: React.FC<SourceCopyButtonProps> = ({
  sourceId,
  sourceName,
  targetSourceId,
  targetSourceName,
  onCopy,
}) => {
  const handleCopyClick = async () => {
    const result = await Swal.fire({
      title: 'Copy Source Schedule',
      text: `Are you sure you want to copy the schedule from "${sourceName}" to "${targetSourceName}"? This will override the existing schedule.`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, copy it',
      cancelButtonText: 'Cancel',
      confirmButtonColor: '#4F46E5',
      cancelButtonColor: '#6B7280'
    });

    if (result.isConfirmed) {
      onCopy(sourceId, targetSourceId);
      Swal.fire({
        title: 'Schedule Copied',
        text: `Schedule has been successfully copied to "${targetSourceName}"`,
        icon: 'success',
        timer: 2000,
        showConfirmButton: false
      });
    }
  };

  return (
    <button
      onClick={handleCopyClick}
      className="p-1.5 text-gray-400 hover:text-gray-600 rounded-lg hover:bg-gray-50"
      title={`Copy schedule to ${targetSourceName}`}
    >
      <FaRegCopy size={16} />
    </button>
  );
};