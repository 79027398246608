import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { StoreSelector } from './components/StoreSelector';
import { DeliveryMethodSelect } from './components/DeliveryMethodSelect';
import { SourceTabs } from './components/SourceTabs';
import { SourceScheduleC } from './components/SourceSchedule';
import { useSlotsManagement } from 'hooks/useSlotsManagement';
import { FaRegSave } from "react-icons/fa";
import { SlotsData, Source, SourceSchedule } from 'interfaces/ISlots';
import usePreRender from 'hooks/usePreRender';
import { cn, areObjectsDifferent, removeEmptyKeys } from 'utils/utils';
import useLogisticSourcesResources from 'hooks/entities/useLogisticSourcesResources';
import Swal from 'sweetalert2';

interface SlotsEditorProps {
  sourceInitList: Array<Source>
  onLoadMore: () => void;
  onFilter: (search: string) => void;
}

export const SlotsEditor: React.FC<SlotsEditorProps> = ({
  sourceInitList,
  onLoadMore,
  onFilter
}) => {
  const { _color } = usePreRender()
  const [activeSource, setActiveSource] = useState<string>(sourceInitList[0]._id);
  const [deliveryMethods, setDeliveryMethods] = useState<SourceSchedule[]>(sourceInitList[0].schedule);
  const [deliveryMethod, setDeliveryMethod] = useState(0);
  const { set_loading_app, put_upsert_source_action } = useLogisticSourcesResources()

  const form = useForm<SlotsData>({
    defaultValues: {
      sources: sourceInitList,
      selectedStores: sourceInitList,
    }
  });

  const { selectedStores, sources, handleStoreChange, getSourceIndex } = useSlotsManagement(form);

  // Set initial active source when stores are selected
  useEffect(() => {    
    if (selectedStores.length > 0 && !activeSource) {
      setActiveSource(selectedStores[0]._id);
      let storeData = sources.find((sourceData:any) => sourceData._id === selectedStores[0]._id);
      if(storeData){
        setDeliveryMethods(storeData.schedule); 
      }
    } else if (selectedStores.length === 0) {
      setActiveSource('');
    } else if (!selectedStores.find((store:any) => store._id === activeSource)) {
      setActiveSource(selectedStores[0]._id);
      let storeData = sources.find((sourceData:any) => sourceData._id === selectedStores[0]._id);
      if(storeData){
        setDeliveryMethods(storeData.schedule); 
      }
    }
  }, [sources, selectedStores, activeSource]);

  useEffect(() => {
    form.setValue(`sources`, sourceInitList);
    form.setValue(`selectedStores`, sourceInitList);
  },
  // eslint-disable-next-line 
  [sourceInitList]);

  const handleSubmit = async (data: SlotsData) => {
    try {
      set_loading_app(true)
      //Get sources with diferent data
      let sourcesToEdit = data.sources.filter((source:any) => {
          let findSourceSelected = data.selectedStores.find((store) => store._id === source._id);
          return areObjectsDifferent(source, findSourceSelected);
      }).map((source:any) => {
        source.id = source._id;
        delete source._id;
        delete source.ownerId;
        source.schedule.map((schedule:any) => {
          if(Array.isArray(schedule.deliverySlots)){
            var obj = schedule.deliverySlots.reduce(function(acc:any, cur:any, i:any) {
              acc[i] = cur;
              return acc;
            }, {});
            schedule.deliverySlots = obj;
          }
          if(schedule.slots){
            delete schedule.slots;
          }
          schedule.deliverySlots = removeEmptyKeys(obj);
          return schedule;
        });
        return source;
      });
      const res: any = await put_upsert_source_action({ body: [...sourcesToEdit] })
      if (res?.data?.code === 200) {
        Swal.fire({
          title: "Hecho",
          text: "Horarios editados para los sources " ,
          icon: "success",
        })
      }
      set_loading_app(false)
    } catch (error: any) {
      Swal.fire({
        title: "Error",
        text: JSON.stringify(error?.response?.data?.message),
        icon: "error",
      })
      set_loading_app(false)
    }
  };

  const handleDeliveryMethodChange = (method: number) => {
    setDeliveryMethod(method);
  };

  const handleSourceChange = (sourceId: string) => {
    setActiveSource(sourceId);
    let storeData = sources.find((sourceData:any) => sourceData._id === sourceId);
    if(storeData){
      setDeliveryMethods(storeData.schedule); 
    }
  };

  const handleCopySource = (fromSourceId: string, toSourceId: string) => {
    const fromSourceIndex = getSourceIndex(fromSourceId);
    const toSourceIndex = getSourceIndex(toSourceId);
    
    if (fromSourceIndex !== -1 && toSourceIndex !== -1) {
      const sourceSchedule = form.getValues(`sources.${fromSourceIndex}.schedule`);
      form.setValue(`sources.${toSourceIndex}.schedule`, { ...sourceSchedule });
    }
  };
  
  const handleSourceLoadMore = () => {
    onLoadMore()
  };

  const handleSourceFilter = (search: string) => {
    onFilter(search)
  };

  return (
    <div className="min-h-screen bg-[#F8FAFC]">
      <div>
        <form id="schedule-form" onSubmit={form.handleSubmit(handleSubmit)} className="flex-1">
        </form>
        <div className="space-y-4 mb-6">
          <div className="flex flex-row gap-3 items-center mb-6">
            <div className="min-w-[90%]">
              <StoreSelector
                sources={sourceInitList}
                selectedStores={selectedStores}
                onStoreChange={handleStoreChange}
                onFilterChange={handleSourceFilter}
              />
            </div>
            <div className="flex justify-center items-top min-h-[100%] min-w-[10%]">
              <button
                type="submit"
                form="schedule-form"
                className={cn(
                  "inline-flex items-center gap-2 px-6 py-2.5 text-white rounded-lg transition-colors font-medium max-h-[35px]",
                  `bg_${_color}`,
                )}
              >
                <FaRegSave size={20} />
                Guardar
              </button>
            </div>
          </div>
        </div>
        
        <div className="bg-white rounded-xl shadow-sm border border-gray-200 flex">
          {activeSource && selectedStores.length > 0 && (
            <SourceTabs
              sources={selectedStores}
              activeSource={activeSource}
              onSourceChange={handleSourceChange}
              onLoadMore={handleSourceLoadMore}
              onCopySource={handleCopySource}
            />
          )}
          
          <div className="p-2 flex flex-col">
            <div className="space-y-4 mb-6">
              {activeSource && deliveryMethods && (<DeliveryMethodSelect
                list={deliveryMethods}
                value={deliveryMethod}
                onChange={handleDeliveryMethodChange}
              />)}
            </div>
          
              {activeSource && (
                <SourceScheduleC
                  sourceIndex={getSourceIndex(activeSource)}
                  deliveryIndex={deliveryMethod}
                  form={form}
                />
              )}
          
          </div>
        </div>
      </div>
    </div>
  );
}