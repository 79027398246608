import * as yup from "yup"

const schemaValidationRoutes = yup.object().shape({
  sku: yup.string().required("required-field"),
  name: yup.string().required("required-field"),
  safety: yup.number(),
  storePickup: yup.boolean(),
  enabled: yup.boolean(),
  categories: yup.string(),
  barcodes: yup.string(),
  logisticAttributes: yup.object().shape({
    volume: yup.number(),
  }),
  custom: yup.array(
    yup.object().shape({
      key: yup.string().required("required-field"),
      type: yup.string().required("required-field"),
      value: yup.mixed().required("required-field"),
    }),
  ),
})

export default schemaValidationRoutes
