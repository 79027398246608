import cn from "classnames"
import { Link } from "react-router-dom"
import usePreRender from "hooks/usePreRender"

interface IButtonNavigationDetail {
  route: string
  hasDelete?: boolean
  onClickDelete?: () => void
}

const ButtonNavigationDetail = ({ route, hasDelete, onClickDelete }: IButtonNavigationDetail) => {
  const { _color } = usePreRender()

  return (
    <div className="flex flex-row gap-2 font-semibold">
      <Link
        to={route}
        className={cn(
          "w-full lg:w-48 grid place-content-center p-2 text-sm text-md border text-white hover:opacity-80 rounded-md shadow-sm hover:shadow-md transform transition duration-500 hover:scale-105",
          `bg_${_color}`,
        )}
      >
        Editar
      </Link>
      {hasDelete ? (
        <button
          type="button"
          onClick={onClickDelete}
          className={cn(
            "w-full lg:w-48 grid place-content-center p-2 text-sm text-md hover:opacity-80 text-white rounded-md shadow-sm hover:shadow-md transform transition duration-500 hover:scale-105",
            `bg-red-600`,
          )}
        >
          Eliminar
        </button>
      ) : null}
    </div>
  )
}

export default ButtonNavigationDetail
