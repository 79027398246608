import { SearchableSelect, TextField } from "components/Core"
import useSearchMiddlewareOSRM from "hooks/useSearchMiddlewareOSRM"
import { useState } from "react"

interface ILoadCriterias {
  type: "source" | "target"
  control: any
  setValue: any
  watch: any
}

const LoadCriterias = ({ type, control, setValue, watch }: ILoadCriterias) => {
  const { action_search_osrm_action } = useSearchMiddlewareOSRM({})
  const [options, setOptions] = useState<any>([])
  const [isLoadingSearch, setIsLoadingSearch] = useState(false)
  const [hasCriteria3, setHasCriteria3] = useState(false)

  const criteria0 = watch(`${type}.data.criteria0`)
  const criteria1 = watch(`${type}.data.criteria1`)
  const criteria2 = watch(`${type}.data.criteria2`)
  const criteria3 = watch(`${type}.data.criteria3`)

  const getLocationsAction = async (queryString: string, fields: any, filters: any = {}) => {
    let params = { skipRecords: 0, maxRecords: 100, queryString, fields, filters }
    try {
      setOptions([])
      setIsLoadingSearch(true)
      let res: any = await action_search_osrm_action("locations", params)
      if (Boolean(res?.data?.message?.total)) {
        let result = res?.data?.message?.records
        setIsLoadingSearch(false)
        return result
      } else {
        setIsLoadingSearch(false)
        return []
      }
    } catch (error) {
      setIsLoadingSearch(false)
      console.log("Error", JSON.stringify(error))
    }
  }

  const getCriteria0Action = async (queryString: string) => {
    let result = await getLocationsAction(queryString, ["criteria0"])
    result = result?.map((loc: any) => ({ id: loc?.criteria0, name: loc?.criteria0 }))
    result = flatResults(result, "criteria0")
    setOptions(result)
    return result
  }

  const getCriteria1Action = async (queryString: string) => {
    setOptions([])
    let result = await getLocationsAction(queryString, ["criteria1"], { criteria0: criteria0?.id })
    result = result?.map((loc: any) => ({ id: loc?.criteria1, name: loc?.criteria1 }))
    result = flatResults(result, "criteria1")
    setOptions(result)
    return result
  }

  const getCriteria2Action = async (queryString: string) => {
    setOptions([])
    let result = await getLocationsAction(queryString, ["criteria2", "criteria3"], {
      criteria0: criteria0?.id,
      criteria1: criteria1?.id,
    })
    checkIfCriteria3Exists(result)
    result = result?.map((loc: any) => ({ id: loc?.criteria2, name: loc?.criteria2 }))
    result = flatResults(result, "criteria2")
    setOptions(result)
    return result
  }

  const getCriteria3Action = async (queryString: string) => {
    setOptions([])
    let result = await getLocationsAction(queryString, ["criteria3"], {
      criteria0: criteria0?.id,
      criteria1: criteria1?.id,
      criteria2: criteria2?.id,
    })
    result = result?.map((loc: any) => ({ id: loc?.criteria3, name: loc?.criteria3 }))
    result = flatResults(result, "criteria3")
    setOptions(result)
    return result
  }

  const checkIfCriteria3Exists = (data: any) => {
    const result = (data || []).map(({ criteria3 }: any) => criteria3 && true)
    setHasCriteria3(result.includes(true))
  }

  const flatResults = (records: any, key: string) => {
    const uniqueValues = new Set(records.map((item: any) => item["id"]))
    return Array.from(uniqueValues).map((value) => {
      const newObj: any = { id: value, name: value, type: key }
      return newObj
    })
  }

  return (
    <div className="w-full flex flex-col gap-4">
      {criteria0?.id ? (
        <TextField label="Criteria0" value={criteria0?.id} disabled />
      ) : (
        <SearchableSelect
          name={`${type}.data.criteria0`}
          control={control}
          options={options}
          onSearch={getCriteria0Action}
          placeholder="Criteria0"
          isLoading={isLoadingSearch}
          setOptions={setOptions}
        />
      )}
      {criteria0?.id ? (
        criteria1?.id ? (
          <TextField label="Criteria1" value={criteria1?.id} disabled />
        ) : (
          <SearchableSelect
            name={`${type}.data.criteria1`}
            control={control}
            options={options}
            onSearch={getCriteria1Action}
            placeholder="Criteria1"
            isLoading={isLoadingSearch}
            setOptions={setOptions}
          />
        )
      ) : null}
      {criteria1?.id ? (
        criteria2?.id ? (
          <TextField label="Criteria2" value={criteria2?.id} disabled />
        ) : (
          <SearchableSelect
            name={`${type}.data.criteria2`}
            control={control}
            options={options}
            onSearch={getCriteria2Action}
            placeholder="Criteria2"
            isLoading={isLoadingSearch}
            setOptions={setOptions}
          />
        )
      ) : null}
      {hasCriteria3 && criteria2?.id ? (
        criteria3?.id ? (
          <TextField label="Criteria3" value={criteria3?.id} disabled />
        ) : (
          <SearchableSelect
            name={`${type}.data.criteria3`}
            control={control}
            options={options}
            onSearch={getCriteria3Action}
            placeholder="Criteria3"
            isLoading={isLoadingSearch}
            setOptions={setOptions}
          />
        )
      ) : null}
      <div className="w-full flex items-center justify-end">
        <p onClick={() => setValue(`${type}.data`, {})} className="text-[#F9004D] underline cursor-pointer">
          Limpiar
        </p>
      </div>
    </div>
  )
}

export default LoadCriterias
