// import { IFiltersTable } from "interfaces/IFilters"
import { FiltersActionsType } from "store/types/AppContextType"

// export const FiltersReducer = (state: Array<IFiltersTable>, action: FiltersActionsType) => {
export const FiltersReducer = (state: any, action: FiltersActionsType) => {
  switch (action.type) {
    case "ADD_FILTER":
      let curr_data = [...state, action.payload]
      curr_data = curr_data
        .reverse()
        .filter((value, index, self) => index === self.findIndex((t) => t.id === value.id))
        .reverse()
      return curr_data
    case "DELETE_FILTER":
      return state.filter((item: any) => item?.id !== action.payload)
    case "CLEAR_FILTERS":
      return []
    default:
      return state
  }
}
