import { Breadcrumb, StatusTimeline, TitleScreen } from "components/Core"
import CollapseWithDetail from "components/Core/Collapse/WithDetail"
// import DetailHistoryStatus from "../../components/DetailHistoryStatus"
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { format_date } from "helpers/datetime_helper"
import useSearchMiddlewareOSRM from "hooks/useSearchMiddlewareOSRM"
import translatesObj from "overrides/theme/entel/base/translates/translation.json"

const DetailScreen = () => {
  const statusses: any = translatesObj
  const [order, set_data] = useState<any>()
  const { order_id } = useParams()
  const { data: data_orders, search_osrm_action: search_osrm_action_orders } = useSearchMiddlewareOSRM({
    entity: "orders",
  })

  useEffect(() => {
    search_osrm_action_orders({ from: 0, size: 1, filters: { id: order_id } })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (Boolean(data_orders?.records.length)) {
      const get_order = async () => {
        let order_from_req = data_orders?.records[0]
        set_data(order_from_req)
      }
      get_order()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_orders])

  const courierName = order?.logisticPlan?.sources[0]?.route
    ? order?.logisticPlan?.sources[0]?.route[0]?.courier?.name
    : ""

  const currentState = order?._omnixStateMachine?.currentState?.state?.name || ""

  return (
    <div className="w-full h-full flex flex-col items-start justify-start">
      <Breadcrumb
        data={[
          { name: "Listado de órdenes", href: "/orders" },
          { name: "Detalle de orden", href: `/orders/${order_id}` },
        ]}
      />
      <div className="w-full flex flex-col justify-center items-center py-4 px-6">
        <TitleScreen title="Detalle de orden" string_id={order_id} />
      </div>
      <div className="w-full h-auto py-4 px-6">
        <StatusTimeline statuses={order?._omnixStateMachine?.states} />
        {/* <DetailHistoryStatus statusses={order?._omnixStateMachine?.states} /> */}
      </div>
      <div className="w-full flex flex-col lg:flex-row gap-6 py-4 px-6">
        <div className="w-full lg:w-1/2 flex flex-col gap-4">
          <div className="w-full flex flex-col gap-2">
            <h4 className="font-semibold text-[#4C4C4C]">Información</h4>
            <div className="grid grid-cols-2 grid-rows-3 bg-white rounded-xl border divide-x divide-y">
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Número de orden</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{order_id}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Estado de la orden</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{statusses[currentState]?.title || currentState}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Canal</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{order?.channel}</p>
            </div>
          </div>
          <CollapseWithDetail title="Información de envío" details={order?.shippingAddress} />
          <CollapseWithDetail title="Información de facturación" details={order?.billingAddress} />
          <CollapseWithDetail title="Información de cliente" details={order?.customer} />
          <CollapseWithDetail title="Información de pago" details={order?.paymentInfo} />
        </div>
        <div className="w-full lg:w-1/2 flex flex-col gap-4">
          <div className="w-full flex flex-col gap-2">
            <h4 className="font-semibold text-[#4C4C4C]">Detalle de la orden</h4>
            <div className="grid grid-cols-2 grid-rows-4 bg-white rounded-xl border divide-x divide-y">
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Fecha creación orden</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{format_date(order?.creationDate)}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Courier</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{courierName || ""}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Estado de la orden</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{statusses[currentState]?.title || currentState}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">ID Canal de venta</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{order?.salesChannelId}</p>
            </div>
          </div>
          <CollapseWithDetail title="Artículos" details={order?.items} />
          {/* <div className="w-full flex flex-col gap-2">
            <h4 className="font-semibold text-[#4C4C4C]">Grupo de envíos</h4>
            <div className="w-full flex flex-col bg-white rounded-xl border divide-x divide-y">
              <div className="w-full flex flex-row items-center justify-between">
                <p className="text-sm text-[#F9004D] underline px-4 py-3">#fg234567890</p>
              </div>
              <div className="w-full flex flex-row items-center justify-between">
                <p className="text-sm text-[#F9004D] underline px-4 py-3">#fg234567890</p>
              </div>
              <div className="w-full flex flex-row items-center justify-between">
                <p className="text-sm text-[#F9004D] underline px-4 py-3">#fg234567890</p>
              </div>
            </div>
          </div> */}
          {/* <div className="w-full flex flex-col gap-2">
            <h4 className="font-semibold text-[#4C4C4C]">Adjuntos</h4>
            <div className="w-full flex flex-col bg-white rounded-xl border py-3">
              <p className="text-sm text-[#F9004D] underline px-4 py-2">img_8923.png</p>
              <p className="text-sm text-[#F9004D] underline px-4 py-2">relevant-info.pdf</p>
              <p className="text-sm text-[#F9004D] underline px-4 py-2">relevant-info.jdf</p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default DetailScreen
