import * as yup from "yup"

const schemaValidationRoutes = yup.object().shape({
  id: yup.string().required(),
  status: yup.boolean(),
  source: yup.object().shape({
    type: yup.string().required(),
    data: yup.object(),
    // .when("source", (sourceType: any) => {
    //   if (sourceType === "Source") {
    //     return yup
    //       .object()
    //       // .shape({ id: yup.string().required("required-field"), name: yup.string().required("required-field") })
    //   }
    //   return yup.object().shape({
    //     criteria0: yup.object().shape({ id: yup.string().required("required-field") }),
    //     criteria1: yup.object().shape({ id: yup.string().required("required-field") }),
    //     criteria2: yup.object().shape({ id: yup.string().required("required-field") }),
    //     criteria3: yup.object().shape({ id: yup.string() }),
    //   })
    // }),
  }),
  target: yup.object().shape({
    type: yup.string().required(),
    data: yup.object(),
    // .when("target.type", ([targetType]: any) => {
    //   if (targetType === "Source") {
    //     return yup
    //       .object()
    //       // .shape({ id: yup.string().required("required-field"), name: yup.string().required("required-field") })
    //   }
    //   return yup.object().shape({
    //     criteria0: yup.object().shape({ id: yup.string().required("required-field") }),
    //     criteria1: yup.object().shape({ id: yup.string().required("required-field") }),
    //     criteria2: yup.object().shape({ id: yup.string().required("required-field") }),
    //     criteria3: yup.object().shape({ id: yup.string() }),
    //   })
    // }),
  }),
})

export default schemaValidationRoutes
