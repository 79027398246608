import * as yup from "yup"

const schemaValidationLoads = yup.object().shape({
  file: yup
    .mixed()
    .required("El archivo es obligatorio")
    .test("fileType", "El archivo debe ser de tipo .xlsx", (_value: any) => {
      const value = _value && _value[0]
      return value && value?.name && value?.name?.toLowerCase().endsWith(".xlsx")
    }),
  type: yup.string().required("El tipo es obligatorio"),
})

export default schemaValidationLoads
