import { Breadcrumb, ButtonGroup, TitleScreen } from "components/Core"
import useForm from "hooks/useForm"
import schemaValidationChannels from "utils/yup/channel_schema"
import channel_values from "utils/forms_default/channel_values"
import { FormChannel } from "components/FormEntities"
import useChannelsResources from "hooks/entities/useChannelsResources"
import { useNavigate } from "react-router-dom"
import { parsed_body_to_send } from "components/FormEntities/FormChannel/channel.config"
import Swal from "sweetalert2"

const NewScreen = () => {
  const navigate = useNavigate()
  const { control, errors, handleSubmit } = useForm({
    defaultValues: channel_values,
    schema: schemaValidationChannels,
  })
  const { set_loading_app, put_upsert_channel_action } = useChannelsResources()

  const on_submit = async (data: any) => {
    try {
      set_loading_app(true)
      const body = parsed_body_to_send(data)
      let res: any = await put_upsert_channel_action({ body })
      if (res?.data?.code === 200) {
        Swal.fire({
          title: "Hecho",
          text: "Canal creado",
          icon: "success",
        })
        navigate(`/channels`)
      }
      set_loading_app(false)
    } catch (error: any) {
      Swal.fire({
        title: "Error",
        text: JSON.stringify(error?.response?.data?.message),
        icon: "error",
      })
      set_loading_app(false)
    }
  }
  console.log("errors", errors)

  return (
    <div className="w-full h-full flex flex-col items-start justify-start">
      <Breadcrumb
        data={[
          { name: "Listado de canales", href: "/channels" },
          { name: "Creación de canales", href: "/channels/new" },
        ]}
      />
      <div className="w-full flex flex-col justify-center items-center py-4 px-6">
        <TitleScreen title="creación de canales" />
        {/* <p>ItemNewScreen</p> */}
      </div>
      <form className="w-full flex flex-col py-2 px-4 lg:px-10" onSubmit={handleSubmit(on_submit)}>
        <div className="w-full flex flex-row items-center justify-end lg:pb-10 py-2">
          <ButtonGroup />
        </div>
        <FormChannel control={control} errors={errors} />
      </form>
    </div>
  )
}

export default NewScreen
