import { Breadcrumb, ButtonGroup, TitleScreen } from "components/Core"
import { FormItem } from "components/FormEntities"
import useForm from "hooks/useForm"
import item_values from "utils/forms_default/item_values"
import schemaValidationItems from "utils/yup/items_schema"
import { parsed_body_to_send } from "components/FormEntities/FormItem/item.config"
import { useFieldArray } from "react-hook-form"
import useItemsResources from "hooks/entities/useItemsResources"
import { useNavigate } from "react-router-dom"
import Swal from "sweetalert2"

const NewScreen = () => {
  const navigate = useNavigate()

  const { control, errors, handleSubmit, watch } = useForm({
    defaultValues: item_values,
    schema: schemaValidationItems,
  })
  const { set_loading_app, put_load_items_action } = useItemsResources()
  const custom_field_form = useFieldArray({ control, name: "custom" })

  const on_submit = async (data: any) => {
    try {
      set_loading_app(true)
      const body = parsed_body_to_send(data)
      let res: any = await put_load_items_action({ body })
      if (res?.data?.code === 200) {
        Swal.fire({
          title: "Hecho",
          text: "Artículo creado",
          icon: "success",
        })
        navigate(`/items/${body.sku}`)
      }
      set_loading_app(false)
    } catch (error: any) {
      Swal.fire({
        title: "Error",
        text: JSON.stringify(error?.response?.data?.message),
        icon: "error",
      })
      set_loading_app(false)
    }
  }
  console.log("errors", errors)

  return (
    <div className="w-full h-full flex flex-col items-start justify-start">
      <Breadcrumb
        data={[
          { name: "Listado de artículos", href: "/items" },
          { name: "Creación de artículos", href: "/items/new" },
        ]}
      />
      <div className="w-full flex flex-col justify-center items-center py-4 px-6">
        <TitleScreen title="creación de artículos" />
        {/* <p>ItemNewScreen</p> */}
      </div>
      <form className="w-full flex flex-col py-2 px-4 lg:px-10" onSubmit={handleSubmit(on_submit)}>
        <div className="w-full flex flex-row items-center justify-end lg:pb-10 py-2">
          <ButtonGroup />
        </div>
        <FormItem control={control} errors={errors} watch={watch} custom_field_form={custom_field_form} />
      </form>
    </div>
  )
}

export default NewScreen
