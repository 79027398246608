import { Breadcrumb, ButtonGroup, TitleScreen } from "components/Core"
import useForm from "hooks/useForm"
import schemaValidationChannels from "utils/yup/channel_schema"
import channel_values from "utils/forms_default/channel_values"
import { FormChannel } from "components/FormEntities"
import { useNavigate, useParams } from "react-router-dom"
import { parsed_body_to_send, fill_form_from_data } from "components/FormEntities/FormChannel/channel.config"
import { useEffect, useState } from "react"
import useSearchMiddlewareOSRM from "hooks/useSearchMiddlewareOSRM"
import Swal from "sweetalert2"
import useChannelsResources from "hooks/entities/useChannelsResources"

const NewScreen = () => {
  const navigate = useNavigate()
  const [channel, set_channel] = useState<any>()
  const { channel_id } = useParams()
  const { control, errors, handleSubmit, reset } = useForm({
    defaultValues: channel_values,
    schema: schemaValidationChannels,
  })
  const { set_loading_app, put_upsert_channel_action } = useChannelsResources()
  const { data: data_channels, search_osrm_action: search_osrm_action_channels } = useSearchMiddlewareOSRM({
    entity: "channels",
  })

  useEffect(() => {
    search_osrm_action_channels({ from: 0, size: 1000, filters: { id: channel_id } })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (Boolean(data_channels?.records.length)) {
      const get_channel = async () => {
        let channel_from_req = data_channels?.records[0]
        set_channel(channel_from_req)
      }
      get_channel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_channels])

  useEffect(() => {
    // RESET FORM TO FILL FIELDS
    channel && reset(fill_form_from_data(channel))
    // eslint-disable-next-line
  }, [channel])

  const on_submit = async (data: any) => {
    try {
      set_loading_app(true)
      const body = parsed_body_to_send(data)
      let res: any = await put_upsert_channel_action({ body })
      if (res?.data?.code === 200) {
        Swal.fire({
          title: "Hecho",
          text: "Canal creado",
          icon: "success",
        })
        navigate(`/channels`)
      }
      set_loading_app(false)
    } catch (error: any) {
      Swal.fire({
        title: "Error",
        text: JSON.stringify(error?.response?.data?.message),
        icon: "error",
      })
      set_loading_app(false)
    }
  }
  console.log("errors", errors)

  return (
    <div className="w-full h-full flex flex-col items-start justify-start">
      <Breadcrumb
        data={[
          { name: "Listado de canales", href: "/channels" },
          { name: "Edición de canal", href: "/channels/new" },
        ]}
      />
      <div className="w-full flex flex-col justify-center items-center py-4 px-6">
        <TitleScreen title="Edición de canal" string_id={channel_id} />
        {/* <p>ItemNewScreen</p> */}
      </div>
      <form className="w-full flex flex-col py-2 px-4 lg:px-10" onSubmit={handleSubmit(on_submit)}>
        <div className="w-full flex flex-row items-center justify-end lg:pb-10 py-2">
          <ButtonGroup />
        </div>
        <FormChannel control={control} errors={errors} />
      </form>
    </div>
  )
}

export default NewScreen
