import { Button, Collapse } from "components/Core"
import { Select } from "flowbite-react"
import { useState } from "react"
import useSelectServiceOptions from "components/Filters/FieldType/FieldSelectService/useSelectServiceOptions"

interface IFieldSelectService {
  field: any
  apply_filter: any
}

const FieldSelectService = ({ field: _field, apply_filter }: IFieldSelectService) => {
  const { id, label } = _field
  const [value, set_value] = useState<string>("")
  const { options } = useSelectServiceOptions({ field: id })

  const handle_change_value = (e: any) => {
    e.preventDefault()
    set_value(e?.target?.value)
  }

  const apply_filter_value = () => {
    apply_filter({ ..._field, value })
  }

  return (
    <Collapse name={id} header={<h1>{label}</h1>}>
      <div className="w-full flex flex-row items-center justify-center gap-2">
        <div className="relative w-full h-14">
          <Select onChange={handle_change_value} value={value} className="w-full h-full">
            {options.length ? (
              <>
                <option value="" disabled>
                  <p>Seleccione...</p>
                </option>
                {options.map((item: any, i: number) => (
                  <option key={i} value={item?.value}>
                    <p>{item?.label}</p>
                  </option>
                ))}
              </>
            ) : (
              <option disabled>Sin opciones</option>
            )}
          </Select>
        </div>
        <Button label="Aplicar" className="w-28 !p-0" onClick={apply_filter_value} />
      </div>
    </Collapse>
  )
}

export default FieldSelectService
