import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import { Select, Table, EditButton, Modal, TextField, AddButton, TrashButton } from "components/Core"
import useForm from "hooks/useForm"
import { Controller, useFieldArray } from "react-hook-form"
// import { Areas } from "components/DinamicFormSections"
import usePreRender from "hooks/usePreRender"
import cn from "classnames"
import useAreasScreenUtils from "hooks/useAreasScreenUtils"
import { TruncateText } from "components/Core/Text/TruncateText"
import SearchAutocomplete from "components/CoveragesSearchAutocompleteSources"
import { DAYS_OF_WEEK_NUMBER, UNIT_TIME } from "constants/FORM_CONSTANTS"
import * as yup from "yup"
import { Coverages } from "components/DinamicFormSections"

interface IFinalForm {
  body_to_send: any
  current_commune: any
  set_body_to_send: Dispatch<SetStateAction<any>>
  list_zones_inclusion: any
  list_zones_exclusion: any
}

const FinalForm = ({
  body_to_send,
  set_body_to_send,
  current_commune,
  list_zones_inclusion,
  list_zones_exclusion,
}: IFinalForm) => {
  const [SOURCES, set_sources] = useState([])
  const [sources_text, set_sources_text] = useState([])
  const [show_more_columns, set_show_more_columns] = useState(true)
  const [days_of_week, set_days_of_week] = useState([])
  const [working_times, set_working_times] = useState<any>([])

  const {
    CHANNELS,
    SHIPPING_TYPES_COVERAGES,
    DELIVERY_TYPES,
    COURIERS,
    TABLE_COVERAGES,
    set_table_coverages,
    post_list_table_combined,
  } = useAreasScreenUtils()

  const { _color } = usePreRender()
  const modal_ref = useRef<any>(null)

  const { register, control, watch } = useForm({
    schema: yup.object().shape({}),
  })

  /*-- working time---*/
  const {
    control: controlCoverages,
    errors: errorsCoverages,
    watch: watchCoverages,
  } = useForm({
    defaultValues: {},
    schema: yup.object().shape({}),
  })

  const working_time_field_form = useFieldArray({ control: controlCoverages, name: "workingTime" })
  const add_new_working_time = () =>
    working_time_field_form.append({
      daysOfWeek: [],
      from: "",
      to: "",
    })

  const __watchCoverages = watchCoverages("workingTime")

  useEffect(() => {
    set_body_to_send({
      ...body_to_send,
      deliveryDays: days_of_week.length ? days_of_week : [],
      workingTime: working_times,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [days_of_week, working_times])

  useEffect(() => {
    //console.log(body_to_send)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [body_to_send])

  const handle_click_open_working_time = () => {
    modal_ref?.current?.open()
  }

  const handle_click_close_working_time = () => {
    modal_ref.current?.close()
  }

  const save_matrix = () => {
    set_working_times(JSON.parse(JSON.stringify(__watchCoverages)))
    console.log(body_to_send)
    handle_click_close_working_time()
  }

  const onCallbackSource = (items: any) => {
    set_sources(
      items?.map((item: any) => ({
        id: item?.id,
        name: item?.name,
        alias: item?.aliases[0],
      })),
    )

    set_sources_text(items?.map((item: any) => item?.name))
  }

  const [watch_channel, watch_shippingType, watch_deliveryType, watch_couriers] = watch([
    "channel",
    "shippingType",
    "deliveryType",
    "couriers",
  ])

  const remove_all_working_time = () => {
    working_time_field_form.replace([])
  }

  useEffect(() => {
    if (
      watch_channel?.length ||
      watch_shippingType?.length ||
      watch_deliveryType?.length ||
      SOURCES?.length ||
      watch_couriers?.length
    ) {
      const body = {
        channels: watch_channel?.length ? watch_channel : undefined,
        shippingTypes: watch_shippingType?.length ? watch_shippingType : undefined,
        deliveryTypes: watch_deliveryType?.length ? watch_deliveryType : undefined,
        sources: SOURCES?.length ? SOURCES : undefined,
        couriers: watch_couriers?.length
          ? watch_couriers?.map((courier: any) => ({
              id: courier,
            }))
          : undefined,
      }

      post_list_table_combined(body)
    } else {
      set_table_coverages([])
    }

    set_show_more_columns(
      watch_channel?.length &&
        watch_shippingType?.length &&
        watch_deliveryType?.length &&
        SOURCES?.length &&
        watch_couriers?.length,
    )
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch_channel, watch_shippingType, watch_deliveryType, SOURCES, watch_couriers])

  useEffect(() => {
    set_body_to_send({
      ...body_to_send,
      sources: SOURCES,
      couriers: watch_couriers,
    })
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SOURCES, watch_couriers])

  useEffect(
    () => {
      const subscription = watch((value) => {
        if (value.leadTime) {
          set_table_coverages((prev: any) =>
            prev.map((item: any, index: number) => ({
              ...item,
              leadTime: {
                value: value.leadTime[index]?.value || 0,
                unit: value.leadTime[index]?.unit || "",
              },
            })),
          )
        }
      })

      return () => subscription.unsubscribe()
    },
    // eslint-disable-next-line
    [watch],
  )

  useEffect(
    () => {
      set_body_to_send({
        ...body_to_send,
        combinations: TABLE_COVERAGES,
      })
    },
    // eslint-disable-next-line
    [TABLE_COVERAGES],
  )

  return (
    <>
      <div className="w-full flex flex-col items-center gap-4">
        <div className="w-full flex flex-col lg:flex-row gap-6">
          <div className="w-full flex flex-col lg:flex-row lg:w-3/5 gap-6 rounded-lg border bg-white shadow-md p-4">
            <div className="w-full flex flex-col items-start gap-2">
              <h4 className="text-xl">Comuna</h4>
              <div className="w-full flex flex-col">
                <p className="text-base">{current_commune?.label}</p>
              </div>
            </div>
            <div className="w-full flex flex-col items-start gap-2">
              <h4 className="text-xl">Zona de exclusión</h4>
              <div className="w-full flex flex-col">
                {list_zones_exclusion.map((item: any) => (
                  <div key={item?.id}>
                    <p className="text-base">#{item?.label}</p>
                    {item?.dinamic && (
                      <p key={item?.id} className="text-xs font-light italic">
                        Zona dinámica:
                      </p>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="w-full flex flex-col items-start gap-2">
              <h4 className="text-xl">Zona de inclusión</h4>
              <div className="w-full flex flex-col">
                <div className="w-full flex flex-col">
                  {list_zones_inclusion.map((item: any) => (
                    <div key={item?.id}>
                      <p className="text-base">#{item?.label}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col lg:w-2/5 gap-2 rounded-lg border bg-white shadow-md p-4">
            <div className="w-full flex flex-row">
              <h4 className="text-xl">Configuración de horarios</h4>
              <EditButton
                onClick={handle_click_open_working_time}
                label={days_of_week.length || working_times.length ? "Modificar" : "Agregar"}
              />
            </div>
            {days_of_week.length ? (
              <>
                <div className="w-full flex flex-row">
                  <h5 className="text-lg">Días de entrega</h5>
                </div>
                <div className="w-full flex flex-row">
                  <ul className="list-inside list-disc text-sm italic">
                    <li>
                      {days_of_week.length
                        ? days_of_week?.map((_w: any, _wi: number) => (
                            <span key={_wi}>
                              {DAYS_OF_WEEK_NUMBER.find((day: any) => day?.value === _w)?.label}
                              {_wi !== days_of_week.length - 1 ? ", " : ""}
                            </span>
                          ))
                        : null}
                    </li>
                  </ul>
                </div>{" "}
              </>
            ) : null}
            {working_times.length ? (
              <>
                <div className="w-full flex flex-row">
                  <h5 className="text-lg">Tiempos de trabajo</h5>
                </div>
                <div className="w-full flex flex-row">
                  <ul className="list-inside list-disc text-sm italic">
                    {working_times.length
                      ? working_times?.map((_wt: any, _iwt: number) => (
                          <li key={_iwt}>
                            {_wt.daysOfWeek.length
                              ? _wt.daysOfWeek?.map((_dw: any, _idw: number) => (
                                  <span key={_idw}>
                                    {DAYS_OF_WEEK_NUMBER.find((day: any) => day?.value === _dw)?.label}
                                    {_idw !== _wt.daysOfWeek.length - 1 ? ", " : " "}
                                  </span>
                                ))
                              : null}
                            de {_wt.from} hasta {_wt.to}
                          </li>
                        ))
                      : null}
                  </ul>
                </div>
              </>
            ) : null}
          </div>
        </div>

        <div className="w-full flex flex-col lg:flex-row gap-6">
          <div className="w-full flex flex-col lg:flex-row gap-6 rounded-lg border bg-white shadow-md p-4 items-start">
            <div className="w-full flex flex-col lg:w-1/5 items-start justify-center gap-2">
              <h4 className="font-medium">Canal</h4>
              <Controller
                name="channel"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select label="Seleccionar" multi options={CHANNELS} onChange={onChange} value={value} />
                )}
              />
            </div>
            <div className="w-full flex flex-col lg:w-1/5 items-start justify-center gap-2">
              <h4 className="font-medium">Tipo de envío</h4>
              <Controller
                name="shippingType"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    label="Seleccionar"
                    multi
                    options={SHIPPING_TYPES_COVERAGES}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </div>
            <div className="w-full flex flex-col lg:w-1/5 items-start justify-center gap-2">
              <h4 className="font-medium">Tipo de despacho</h4>
              <Controller
                name="deliveryType"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select label="Seleccionar" multi options={DELIVERY_TYPES} onChange={onChange} value={value} />
                )}
              />
            </div>
            <div className="w-full flex flex-col lg:w-1/5 items-start justify-center gap-2">
              <h4 className="font-medium">MFC</h4>
              <SearchAutocomplete onEventItem={onCallbackSource} />
            </div>
            <div className="w-full flex flex-col lg:w-1/5 items-start justify-center gap-2">
              <h4 className="font-medium">Couriers</h4>
              <Controller
                name="couriers"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select label="Seleccionar" multi options={COURIERS} onChange={onChange} value={value} />
                )}
              />
            </div>
          </div>
        </div>

        <div className="w-full overflow-x-auto">
          <Table>
            <Table.Head>
              <Table.HeadCell>Canal</Table.HeadCell>
              <Table.HeadCell>Tipo de envío</Table.HeadCell>
              <Table.HeadCell>Tipo de despacho</Table.HeadCell>
              <Table.HeadCell className="w-60">MFC</Table.HeadCell>
              <Table.HeadCell>Couriers</Table.HeadCell>
              {show_more_columns && <Table.HeadCell>Lead time</Table.HeadCell>}
            </Table.Head>
            <Table.Body>
              {TABLE_COVERAGES?.length ? (
                TABLE_COVERAGES?.map((item: any, rowIndex: number) => {
                  return (
                    <Table.Row key={rowIndex}>
                      <Table.Cell>{item?.channel}</Table.Cell>
                      <Table.Cell>{item?.shippingType}</Table.Cell>
                      <Table.Cell className="capitalize">{item?.deliveryType?.toLowerCase()}</Table.Cell>
                      <Table.Cell className="whitespace-normal">
                        <TruncateText items={sources_text} />
                      </Table.Cell>
                      <Table.Cell className="whitespace-normal">
                        <TruncateText items={watch_couriers} />
                      </Table.Cell>
                      {show_more_columns && (
                        <Table.Cell className="px-4">
                          <div className="flex">
                            <Controller
                              //name={`leadtime.${rowIndex}.value`}
                              {...register(`leadTime.${rowIndex}.value`)}
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value } }) => (
                                <TextField
                                  label=""
                                  onChange={onChange}
                                  value={value}
                                  style={{ width: "50px", height: "42px", marginRight: "5px" }}
                                />
                              )}
                            />
                            <Controller
                              //name="leadtime.unit"
                              {...register(`leadTime.${rowIndex}.unit`)}
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value } }) => (
                                <Select
                                  label="Unidad"
                                  options={UNIT_TIME}
                                  onChange={onChange}
                                  value={value}
                                  style={{ width: "80px", background: "transparent" }}
                                />
                              )}
                            />
                          </div>
                        </Table.Cell>
                      )}
                    </Table.Row>
                  )
                })
              ) : (
                <Table.Row>
                  <Table.Cell>Sin resultados</Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </div>
      </div>
      <Modal className="w-full h-4/5 min-h-[50vh] max-h-[80vh] lg:w-9/12 px-20 py-8 overflow-auto" ref={modal_ref}>
        <div className="w-full flex flex-row items-center justify-between p-2">
          <h4 className="font-medium text-xl">Configuración de fechas y horarios</h4>
          {working_time_field_form.fields.length > 0 && (
            <TrashButton onClick={remove_all_working_time} label="Eliminar todo" />
          )}
        </div>
        <div className="w-full border-b border-[#919191] mb-10"></div>

        <h6 className="font-medium text-lg">Días de entrega</h6>
        <div className="w-full flex flex-col gap-4 py-2">
          <Select
            label="Dias de la semana"
            multi
            options={DAYS_OF_WEEK_NUMBER}
            onChange={(e: any) => set_days_of_week(e)}
            value={days_of_week}
          />
        </div>

        <h6 className="font-medium text-lg py-5">Tiempos de trabajo</h6>

        <div className="w-full flex flex-col">
          {working_time_field_form.fields.map((workingTimeItem, i) => {
            return (
              <Coverages.WorkingTimeFields
                key={workingTimeItem.id}
                index={i}
                working_time={working_time_field_form}
                control={controlCoverages}
                errors={errorsCoverages}
                title="Horario"
              />
            )
          })}

          <div
            className={`w-full flex flex-row items-center justify-start ${
              working_time_field_form.fields.length ? "px-16" : "px-0 pt-5"
            }`}
          >
            <AddButton onClick={add_new_working_time} />
          </div>
        </div>

        <div className="w-full flex flex-row justify-center gap-6 mt-10 lg:mt-20">
          <button
            type="button"
            onClick={handle_click_close_working_time}
            className={cn(
              "w-full flex flex-col items-center lg:w-48 p-2 text-center text-base font-semibold border hover:opacity-80 rounded-md shadow-sm hover:shadow-md transform transition duration-500 hover:scale-105",
              `border_${_color}`,
              `text_${_color}`,
            )}
          >
            Cerrar
          </button>

          <button
            type="submit"
            onClick={save_matrix}
            className={cn(
              "w-full flex flex-col items-center lg:w-48 p-2 text-center text-base font-semibold hover:opacity-80 text-white rounded-md shadow-sm hover:shadow-md transform transition duration-500 hover:scale-105",
              `bg_${_color}`,
            )}
          >
            Aplicar
          </button>
        </div>
      </Modal>
    </>
  )
}

export default FinalForm
