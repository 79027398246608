import { Breadcrumb, ButtonGroup, TitleScreen } from "components/Core"
import useForm from "hooks/useForm"
import location_values from "utils/forms_default/location_values"
import schemaValidationLocations from "utils/yup/locations_schema"
import { useFieldArray } from "react-hook-form"
import { FormLocation } from "components/FormEntities"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import useSearchMiddlewareOSRM from "hooks/useSearchMiddlewareOSRM"
import { parsed_body_to_send, fill_form_from_data } from "components/FormEntities/FormLocation/location.config"
import useLocationsResources from "hooks/entities/useLocationsResources"
import Swal from "sweetalert2"

const EditScreen = () => {
  const navigate = useNavigate()
  const [location, set_location] = useState<any>()
  const { location_id } = useParams()
  const { data: data_locations, search_osrm_action: search_osrm_action_locations } = useSearchMiddlewareOSRM({
    entity: "locations",
  })
  const { control, errors, watch, reset, handleSubmit } = useForm({
    defaultValues: location_values,
    schema: schemaValidationLocations,
  })
  const criterias_field_form = useFieldArray({ control, name: "criterias" })
  const custom_field_form = useFieldArray({ control, name: "custom" })

  const { set_loading_app, put_upsert_location_action } = useLocationsResources()

  useEffect(() => {
    search_osrm_action_locations({ from: 0, size: 1000, filters: { id: location_id } })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (Boolean(data_locations?.records.length)) {
      const get_location = async () => {
        let location_from_req = data_locations?.records[0]
        set_location(location_from_req)
      }
      get_location()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_locations])

  useEffect(() => {
    // RESET FORM TO FILL FIELDS
    location && reset(fill_form_from_data(location))
    // eslint-disable-next-line
  }, [location])

  const on_submit = async (data: any) => {
    try {
      set_loading_app(true)
      const body = parsed_body_to_send(data)
      const res: any = await put_upsert_location_action({ body })
      if (res?.data?.code === 200) {
        Swal.fire({
          title: "Hecho",
          text: "Source editado",
          icon: "success",
        })
        navigate(`/locations`)
      }
      set_loading_app(false)
    } catch (error: any) {
      Swal.fire({
        title: "Error",
        text: JSON.stringify(error?.response?.data?.message),
        icon: "error",
      })
      set_loading_app(false)
    }
  }
  console.log("errors", errors)

  if (!location) return null
  return (
    <div className="w-full h-full flex flex-col items-start justify-start pb-10">
      <Breadcrumb
        data={[
          { name: "Listado de ubicaciones", href: "/locations" },
          { name: "Edición de ubicación", href: `/locations/${location_id}/edit` },
        ]}
      />
      <div className="w-full flex flex-col justify-center items-center pt-4 px-6">
        <TitleScreen title="Edición de ubicación" string_id={location_id} />
      </div>
      <form className="w-full flex flex-col py-2 px-4" onSubmit={handleSubmit(on_submit)}>
        <div className="w-full flex flex-row items-center justify-end lg:pb-10 py-2">
          <ButtonGroup />
        </div>
        <FormLocation
          control={control}
          errors={errors}
          watch={watch}
          criterias_field_form={criterias_field_form}
          custom_field_form={custom_field_form}
        />
      </form>
    </div>
  )
}

export default EditScreen
