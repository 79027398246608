import React, { useState, useEffect, useRef } from 'react';
import { IoSearch } from "react-icons/io5"
import SelectedItemsModal from 'components/Core/SelectedItemsModal';
import useSearchMiddlewareOSRM from "hooks/useSearchMiddlewareOSRM"

interface Item {
  id: number;
  name: string;
  code: string;
}

interface ISearchAutocomplete {
  onEventItem: (items: []) => void
}

export default function SearchAutocomplete({onEventItem}: ISearchAutocomplete) {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState<Item[]>([]);
  const [selectedItems, setSelectedItems] = useState<Item[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const searchTimeout = useRef<NodeJS.Timeout>();
  const { action_search_osrm_action } = useSearchMiddlewareOSRM({})

  useEffect(() => {
    if (query?.length < 1) {
      setSuggestions([]);
      return;
    }

    const fetchSuggestions = async () => {
      let params = { from: 0, size: 15, queryString: query, fields: ["id", "name", "aliases"] }
      setIsLoading(true);
      try {
        const results:any = await action_search_osrm_action("sources", params)
        setSuggestions(results?.data?.message?.records);
      } catch (error) {
        console.error('Error en buscar los resultados:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }
    searchTimeout.current = setTimeout(fetchSuggestions, 300);

    return () => {
      if (searchTimeout.current) {
        clearTimeout(searchTimeout.current);
      }
    };
  },
  // eslint-disable-next-line
  [query]);

  useEffect(() => {
    const item: any = selectedItems
    onEventItem(item)
  },
  // eslint-disable-next-line 
  [selectedItems]);

  const handleSelect = (item: Item) => {
    if (!selectedItems.some(selected => selected.id === item.id)) {
      setSelectedItems([...selectedItems, item]);
    }
    setQuery('');
    setSuggestions([]);
  };

  const handleRemoveItem = (itemId: number) => {
    const updatedItems = selectedItems.filter(item => item.id !== itemId);
    setSelectedItems(updatedItems);
    
    // Close modal if no items left
    if (updatedItems?.length === 0) {
      setIsModalOpen(false);
    }
  };

  return (
    <div className="w-full">
      <div className="relative">
        <div className="relative">
          <input
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            className="w-full px-4 py-2 pr-10 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Buscar..."
          />
          <div className="absolute right-3 top-2.5">
            {isLoading ? (
              <div className="w-5 h-5 border-2 border-gray-300 border-t-blue-500 rounded-full animate-spin" />
            ) : (
              <IoSearch className="w-5 h-5 text-gray-400" />
            )}
          </div>
        </div>

        {suggestions?.length > 0 && (
          <div className="absolute z-10 w-full mt-1 bg-white rounded-lg shadow-lg border">
            {suggestions.map((item) => (
              <div
                key={item.id}
                onClick={() => handleSelect(item)}
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
              >
                <div className="font-medium text-sm">{item.name}</div>
                <div className="text-xs text-gray-500">ID: {item?.id}</div>
              </div>
            ))}
          </div>
        )}
      </div>

      {selectedItems?.length > 0 && (
        <button
          type='button'
          onClick={() => setIsModalOpen(true)}
          className="mt-2 text-[#FF0F00] text-sm w-full"
        >
          Seleccionados ({selectedItems.length})
        </button>
      )}

      <SelectedItemsModal
        isOpen={isModalOpen}
        items={selectedItems}
        onClose={() => setIsModalOpen(false)}
        onRemoveItem={handleRemoveItem}
        title={"MFC"}
      />
    </div>
  );
}