import { SOURCE_TYPE } from "components/FormEntities/FormSource/source.config"
import { SHIPPING_TYPES } from "constants/FORM_CONSTANTS"
import { format_date } from "helpers/datetime_helper"
import translatesObj from "translates/translation.json"

const statusses: any = translatesObj

export const parsed_filters_on_search = (arr: Array<any>) => {
  let result = {}
  arr.map((item: any) => {
    Object.assign(result, { [item?.field]: item?.value })
    return true
  })

  return result
}

// OOM
export const get_data_table_orders = (data: any) => {
  if (!Boolean(data)) return []

  const columns = [
    "Id",
    "salesChannelId",
    "Canal",
    "Fecha de creación",
    "Estado actual",
    "Tipo de envío",
    "Tipo de entrega",
    "Fecha de entrega",
  ]

  return [
    columns,
    data.map((order: any) => {
      return {
        id: order?.id,
        salesChannelId: order?.salesChannelId,
        channel: order?.channel,
        creationDate: format_date(order?.creationDate),
        currentStatus:
          statusses[order?._omnixStateMachine?.currentState?.state?.name]?.title ||
          order?._omnixStateMachine?.currentState?.state?.name,
        shippingType: SHIPPING_TYPES.find((ship: any) => ship.value === order?.shippingType)?.label,
        deliveryType: order?.deliveryType,
        estimatedDeliveryDate: format_date(order?.estimatedDeliveryDate),
      }
    }),
  ]
}

export const get_data_table_sgs = (data: any) => {
  if (!Boolean(data)) return []

  const columns = [
    "Id",
    "salesChannelId",
    "Canal",
    "Id de orden",
    "Fecha de creación",
    "Estado actual",
    "Tipo de envío",
    "Tienda/bodega",
  ]

  return [
    columns,
    data.map((sg: any) => {
      return {
        id: sg?.id,
        salesChannelId: sg?.salesChannelId,
        channel: sg?.channel,
        orderId: sg?.orderId,
        orderCreationDate: format_date(sg?.orderCreationDate),
        currentStatus:
          statusses[sg?._omnixStateMachine?.currentState?.state?.name]?.title ||
          sg?._omnixStateMachine?.currentState?.state?.name,
        shippingType: SHIPPING_TYPES.find((ship: any) => ship.value === sg?.shippingType)?.label,
        source: `${sg?.source?.name} (${sg?.source?.id})`,
      }
    }),
  ]
}

// OIM
export const get_data_table_sources = (data: any) => {
  if (!Boolean(data)) return []
  const columns = ["id", "nombre", "tipo", "habilitado", "ranking", "Retiro en tienda", "Stock de seguridad"]
  return [
    columns,
    data.map((source: any) => ({
      id: source?.id,
      name: source?.name,
      type: SOURCE_TYPE.find((src: any) => src.value === source?.type)?.label,
      enabled: source?.enabled ? "Si" : "No",
      ranking: JSON.stringify(source?.ranking),
      storePickup: source?.storePickup ? "Si" : "No",
      safety: source?.safety,
    })),
  ]
}

// OLM
export const get_data_table_sources_olm = (data: any) => {
  if (!Boolean(data)) return []
  const columns = ["id", "nombre", "Ubicacion", "Agendamientos", "Capacidad de Volumen", "Sessiones de trabajo", "Horarios de trabajo"]
  return [
    columns,
    data.map((source: any) => ({
      _id: source?.id,
      ...source,
    })),
  ]
}

export const get_data_table_couriers = (data: any) => {
  if (!Boolean(data)) return []
  const columns = ["id", "nombre", "Alias", "Tipo de entrega", "Tipo de envío", "Canales"]

  return [
    columns,
    data.map((courier: any) => ({
      id: courier?.id,
      name: courier?.name,
      alias: courier?.alias,
      deliveryType: courier?.deliveryType,
      shippingType: courier?.shippingType.map(
        (_ship: string) =>
          ` ${SHIPPING_TYPES.find((ship: any) => ship.value === _ship)?.label} ${
            Boolean(courier?.shippingType?.length > 1) ? "-" : ""
          }`,
      ),
      channel: courier?.channel.map((_chnn: string) => ` ${_chnn} -`),
    })),
  ]
}

export const get_data_table_locations = (data: any) => {
  if (!Boolean(data)) return []
  return data.map((location: any) => ({
    id: location?.id,
    criteria0: location?.criteria0,
    criteria1: location?.criteria1,
    criteria2: location?.criteria2,
    criteria3: location?.criteria3,
  }))
}

export const get_data_table_routes = (data: any) => {
  if (!Boolean(data)) return []
  const columns = ["id", "Habilitado", "Tipo de origen", "Origen", "Tipo de destino", "Destino"]

  return [
    columns,
    data.map((route: any) => ({
      id: route?.id,
      status: route?.status ? "Si" : "No",
      sourceType: route?.source?.type,
      source: Object.values(route?.source?.data).join(" - "),
      targetType: route?.target?.type,
      target: Object.values(route?.target?.data).join(" - "),
    })),
  ]
}

export const get_data_table_coverages = (data: any) => {
  const columns = ["Id", "courierId", "routeId", "Estado", "Tipo de entrega", "Tipo de envío"]
  if (!Boolean(data)) return []
  return [
    columns,
    data.map((coverage: any) => ({
      id: coverage?.id,
      courierId: coverage?.courierId,
      routeId: coverage?.routeId,
      status: coverage?.status ? "Habilitado" : "Deshabilitado",
      deliveryType: coverage?.deliveryType,
      shippingType: coverage?.shippingType.map(
        (_ship: string) =>
          ` ${SHIPPING_TYPES.find((ship: any) => ship.value === _ship)?.label} ${
            Boolean(coverage?.shippingType?.length > 1) ? "-" : ""
          }`,
      ),
    })),
  ]
}

export const get_data_table_areas = (data: any) => {
  if (!Boolean(data)) return []
  return data.map((area: any) => ({
    id: area?.id,
  }))
}

export const get_data_table_schedules = (data: any) => {
  if (!Boolean(data)) return []
  const columns = ["id", "tipo", "Habilitado", "tipo de entrega"]
  return [
    columns,
    data.map((schedule: any) => ({
      id: schedule?.id,
      type: schedule?.type,
      status: schedule?.status ? "Si" : "No",
      deliveryType: schedule?.deliveryType,
    })),
  ]
}

export const get_data_table_capacity = (data: any) => {
  if (!Boolean(data)) return []
  const columns = [
    "id",
    "Fecha",
    "Tipo de entrega",
    "Tipo de capacidad",
    "Capacidad usada",
    "Capacidad máxima",
    "Tipo de entidad",
    "Id de entidad",
    "Tipo de productos",
  ]
  return [
    columns,
    data.map((capacity: any) => ({
      id: capacity?.id,
      date: format_date(capacity?.date),
      deliveryType: capacity?.deliveryType,
      type: capacity?.type,
      usedCapacity: capacity?.usedCapacity,
      maxCapacity: capacity?.maxCapacity,
      entityType: capacity?.entity?.type,
      entityId: capacity?.entity?.id,
      productType: capacity?.productType?.map(
        (_product: string) => ` ${_product} ${Boolean(capacity?.productType?.length > 1) ? "-" : ""}`,
      ),
    })),
  ]
}

// OIM
export const get_data_table_channels = (data: any) => {
  if (!Boolean(data)) return []
  const columns = ["Id", "Nombre", "Stock"]
  return [
    columns,
    data.map((channel: any) => ({
      id: channel?.id,
      name: channel?.name,
      stock: channel?.stock?.id,
    })),
  ]
}

export const get_data_table_stock = (data: any) => {
  if (!Boolean(data)) return []
  const columns = [
    "Id",
    "Nombre",
    "Cantidad de tiendas/bodegas",
    "Tipo de stock de seguridad",
    "Valor de stock de seguridad",
  ]
  return [
    columns,
    data.map((stock: any) => ({
      id: stock?.id,
      name: stock?.name,
      sources: stock?.sources?.length,
      safetyType: stock?.safety?.type,
      safetyValue: stock?.safety?.value,
    })),
  ]
}

export const get_data_table_items = (data: any) => {
  if (!Boolean(data)) return []
  const columns = ["Sku", "Nombre", "Habilitado", "Retiro en tienda", "Categorías"]
  return [
    columns,
    data.map((item: any) => ({
      sku: item?.sku,
      name: item?.name,
      enabled: item?.enabled ? "Si" : "No",
      storePickup: item?.storePickup ? "Si" : "No",
      categories: item?.categories.map(
        (_category: string) => ` ${_category} ${Boolean(item?.categories?.length > 1) ? "-" : ""}`,
      ),
    })),
  ]
}

// OTHER

export const get_data_table_massive_load = (data: any) => {
  if (!Boolean(data)) return []
  const columns = ["Id de carga", "Tipo", "Detalle de archivo", "Estado de la carga"]
  return [
    columns,
    data.map((load: any) => ({
      id: load?.id,
      type: load?.type,
      detail: `Nombre: ${load?.file?.name}, Fecha actual: ${format_date(load?.currentStatus?.date, "yyyy-MM-dd HH:mm")}`,
      currentStatus: load?.currentStatus?.name,
    })),
  ]
}
