import { Breadcrumb, TitleScreen } from "components/Core"
import CollapseWithDetail from "components/Core/Collapse/WithDetail"
import ButtonNavigationDetail from "components/Custom/ButtonNavigationDetail"
import useSearchMiddlewareOSRM from "hooks/useSearchMiddlewareOSRM"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

const DetailScreen = () => {
  const [schedule, set_schedule] = useState<any>()
  const { schedule_id } = useParams()
  const { data: data_schedules, search_osrm_action: search_osrm_action_schedules } = useSearchMiddlewareOSRM({
    entity: "schedules",
  })

  useEffect(() => {
    search_osrm_action_schedules({ from: 0, size: 1000, filters: { id: schedule_id } })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (Boolean(data_schedules?.records.length)) {
      const get_schedule = async () => {
        let schedule_from_req = data_schedules?.records[0]
        set_schedule(schedule_from_req)
      }
      get_schedule()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_schedules])

  if (!schedule) return null
  return (
    <div className="w-full h-full flex flex-col items-start justify-start">
      <Breadcrumb
        data={[
          { name: "Listado de agendamiento", href: "/schedule" },
          { name: "Detalle de agendamiento", href: `/schedule/${schedule_id}` },
        ]}
      />
      <div className="w-full flex flex-col justify-center items-center py-4 px-6">
        <TitleScreen title={`Detalle de agendamiento #${schedule_id}`} />
      </div>
      <div className="w-full flex flex-row items-center justify-end px-6">
        <ButtonNavigationDetail route={`/schedule/${schedule_id}/edit`} />
      </div>
      <div className="w-full flex flex-col lg:flex-row gap-6 py-4 px-6">
        <div className="w-full lg:w-1/2 flex flex-col gap-4">
          <div className="w-full flex flex-col gap-2">
            <h4 className="font-semibold text-[#4C4C4C]">Información</h4>
            <div className="grid grid-cols-2 grid-rows-2 bg-white rounded-xl border divide-x divide-y">
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Id de schedule</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{schedule_id}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Tipo</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{schedule?.type}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Tipo de entrega</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{schedule?.deliveryType}</p>
            </div>
          </div>
          <CollapseWithDetail title="Reglas" details={schedule?.rules} />
          <CollapseWithDetail title="Slots de entrega" details={schedule?.deliverySlots} />
          <CollapseWithDetail title="Configuración por defecto sources" details={schedule?.sourceDefaultSettings} />
        </div>
        <div className="w-full lg:w-1/2 flex flex-col gap-4">
          <div className="w-full flex flex-col gap-2">
            <h4 className="font-semibold text-[#4C4C4C]">Detalle del schedule</h4>
            <div className="grid grid-cols-2 grid-rows-3 bg-white rounded-xl border divide-x divide-y">
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Fechas a entregar</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{schedule?.datesToReturn}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Estado</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{schedule?.status ? "Habilitado" : "Deshabilitado"}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Horario habilitado</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">00:00 - 18:00</p>
            </div>
          </div>
          <CollapseWithDetail title="Tipos de envío" details={schedule?.shippingType} />
          <CollapseWithDetail
            title="Configuración por defecto del producto"
            details={schedule?.productDefaultSettings}
          />
        </div>
      </div>
    </div>
  )
}

export default DetailScreen
