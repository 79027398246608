import st from "components/Custom/LabelSwitch/LabelSwitch.module.css"

interface LabelSwitchProps {
  id: string
  label: string
  onChange: any
  value: string
  checked?: boolean
}

const LabelSwitch = ({ id, label, onChange, value, checked }: LabelSwitchProps) => {
  return (
    <div className={st["label_switch__container"]}>
      <div className={st["label_switch__cont_label"]}>
        <label htmlFor={id} className={st["label_switch__label"]}>
          {label}
        </label>
      </div>
      <label className={st["label_switch__switch"]}>
        <input id={id} type="checkbox" checked={checked} onChange={onChange} value={value} />
        <span className={`${st["label_switch__slider"]} ${st["label_switch__round"]}`}></span>
      </label>
    </div>
  )
}

export default LabelSwitch
