import useFiltersTable from "hooks/useFiltersTable"
import { FieldString, FieldDate, FieldBool, FieldSelectSimple, FieldSelectService } from "components/Filters/FieldType"
import table_filters from "helpers/table_filters.json"
import { useLocation } from "react-router-dom"

const DataFilters = () => {
  const _table_filters: any = table_filters
  const { pathname } = useLocation()
  const { apply_filter } = useFiltersTable({
    table_name: "orders",
  })

  const current_table_filters: any = _table_filters?.tables[pathname]?.list || []

  const render_field = (filter: any) => {
    const type = filter?.type
    switch (type) {
      case "string":
        return <FieldString key={filter?.id} apply_filter={apply_filter} field={filter} />
      case "date":
        return <FieldDate key={filter?.id} apply_filter={apply_filter} field={filter} />
      case "bool":
        return <FieldBool key={filter?.id} apply_filter={apply_filter} field={filter} />
      case "select-simple":
        return <FieldSelectSimple key={filter?.id} apply_filter={apply_filter} field={filter} />
      case "select-service":
        return <FieldSelectService key={filter?.id} apply_filter={apply_filter} field={filter} />
      default:
        return null
    }
  }

  return (
    <div className="relative w-full flex flex-col">
      {current_table_filters?.map((filter: any) => render_field(filter))}
    </div>
  )
}

export default DataFilters
