export const parsed_body_to_send = (_data: any) => {
  const { id, name, stock } = _data

  let data = {
    id,
    name,
    stock,
  }

  return data
}

export const fill_form_from_data = (_data: any) => {
  const { id, name, stock } = _data

  let data = {
    id,
    name,
    stock,
  }

  return data
}
