import cn from "classnames"
import { useRef, useEffect, useState, Dispatch, SetStateAction } from "react"
import { Select, AddButton, Modal, TrashButton, TextField } from "components/Core"
import { Controller, useFieldArray } from "react-hook-form"
import usePreRender from "hooks/usePreRender"
import { GOOGLE_MAPS_API_KEY } from "constants/API_URL_CONSTANTS"
import { GoogleMap, DrawingManager, Polygon, useJsApiLoader } from "@react-google-maps/api"
import useForm from "hooks/useForm"
import * as yup from "yup"
import { Coverages } from "components/DinamicFormSections"

interface IFormAreas {
  control: any
  mode: string
  list_region: any
  list_commune: any
  fill_list_commune: any
  map_update: any
  setMap: any
  setDrawingManager: any
  polygons: any
  onPolygonComplete: any
  drawingManager: any
  current_region?: any
  current_commune?: any
  commune_draw_full_exclution_zone: any
  set_coveragesAreas: any
  set_list_zones_inclusion: any
  list_zones_inclusion: any
  set_list_zones_exclusion: any
  list_zones_exclusion: any
  set_zone_dinamic: any
  zone_dinamic: any
  schedule_dinamic_name: any
  set_schedule_dinamic_name: any
  setPolygons: any
  map: any
  body_to_send: any
  set_body_to_send: Dispatch<SetStateAction<any>>
  reset: any
}

const mapContainerStyle = {
  width: "100%",
  height: "400px",
}

const center = {
  lat: -33.447487,
  lng: -70.673676, // Default to Santiago de chile
}

const InitialForm = ({
  control,
  mode,
  list_region,
  list_commune,
  fill_list_commune,
  map_update,
  polygons,
  setDrawingManager,
  setMap,
  onPolygonComplete,
  drawingManager,
  current_commune,
  current_region,
  commune_draw_full_exclution_zone,
  set_coveragesAreas,
  set_list_zones_inclusion,
  list_zones_inclusion,
  set_list_zones_exclusion,
  list_zones_exclusion,
  set_zone_dinamic,
  zone_dinamic,
  schedule_dinamic_name,
  set_schedule_dinamic_name,
  map,
  body_to_send,
  set_body_to_send,
  reset,
}: IFormAreas) => {
  const { _color } = usePreRender()
  const modal_ref = useRef<any>(null)
  const mapRef = useRef<google.maps.Map | null>(null)
  const [selected_zone_dinamic, set_selected_zone_dinamic] = useState("")
  const [polygon_formated, set_polygon_formated]: any = useState([])
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: ["drawing"],
  })

  const list_types_zones: any = [
    {
      label: "Exclusión",
      id: "exclusion",
      value: "exclusion",
    },
    {
      label: "Inclusión",
      id: "inclusion",
      value: "inclusion",
    },
  ]

  const clear_form = () => {
    reset({
      zone_id: "",
      region: current_region?.id,
      commune: current_commune?.id
    })
    set_zone_dinamic(false)
  }

  const {
    control: controlCoverages,
    errors: errorsCoverages,
    watch: watchCoverages,
  } = useForm({
    defaultValues: {},
    schema: yup.object().shape({}),
  })

  const working_time_field_form = useFieldArray({ control: controlCoverages, name: "workingTime" })
  const add_new_working_time = () =>
    working_time_field_form.append({
      daysOfWeek: [],
      from: "",
      to: "",
    })

  const __watchCoverages = watchCoverages("workingTime")

  const change_selected_zone_dinamic = (e: any) => {
    set_selected_zone_dinamic(e)
  }

  const handle_click_open_working_time = () => {
    //clear_form()
    modal_ref?.current?.open()
  }

  const handle_click_close_working_time = () => {
    clear_form()
    modal_ref.current?.close()
  }

  const maps_save_zone_config = () => {
    //set_polygon_formated(polygons)
    const last_value = polygons[polygons.length - 1]

    const _polygon_formated = {
      type: selected_zone_dinamic,
      status: true,
      polygon: {
        type: "Feature",
        properties: {
          id: schedule_dinamic_name,
        },
        geometry: {
          type: "Polygon",

          coordinates: last_value?.path.reduce((acc: any, current: any) => {
            return [...acc, [current.lat, current.lng]]
          }, []),
        },
      },
    }

    const menu = {
      label: _polygon_formated.polygon.properties.id,
      id: _polygon_formated.polygon.properties.id + "-" + Date.now(),
      value: _polygon_formated.polygon.properties.id + "-" + Date.now(),
      dinamic: zone_dinamic,
      workingTime: [...__watchCoverages],
    }

    if (selected_zone_dinamic === "inclusion") {
      set_list_zones_inclusion((prev: any) => [...prev, menu])
    } else {
      set_list_zones_exclusion((prev: any) => [...prev, menu])
    }

    set_polygon_formated((prev: any) => [...prev, _polygon_formated])
    handle_click_close_working_time()
  }

  const remove_all_working_time = () => {
    working_time_field_form.replace([])
  }

  // Initialize drawing manager options
  useEffect(() => {
    if (drawingManager) {
      drawingManager.setOptions({
        drawingMode: google.maps.drawing.OverlayType.POLYGON,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [google.maps.drawing.OverlayType.POLYGON],
        },
        polygonOptions: {
          editable: true,
          draggable: true,
        },
      })

      google.maps.event.addListener(drawingManager, "polygoncomplete", function (polygon: google.maps.Polygon) {
        onPolygonComplete(polygon)
        handle_click_open_working_time()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawingManager])

  useEffect(() => {
    set_coveragesAreas(polygon_formated)
    // eslint-disable-next-line
  }, [polygon_formated])

  useEffect(() => {
    if (map) {
      console.log(polygons)
      polygons.forEach((polygon: any) => {
        new google.maps.Polygon({
          paths: polygon.path,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillOpacity: 0.35,
          fillColor: "red",
          strokeColor: "red",
          map: map, // Attach polygon to the map
        })
      })
    }

    // eslint-disable-next-line
  }, [map])

  return (
    <>
      <div className="w-full flex flex-col items-center gap-4">
        <div className="w-full flex flex-col rounded-lg border bg-white shadow-md">
          <div className="w-full flex flex-col p-4 pt-4 lg:flex-row">
            <div className="w-full flex flex-col lg:flex-row  gap-6">
              <div className="w-full flex flex-col items-start justify-center gap-2">
                <h4 className="font-medium">Región</h4>
                <Controller
                  name="region"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <Select
                      label="Seleccione la región"
                      options={list_region}
                      onChange={(e) => {
                        onChange(e)
                        fill_list_commune(e)
                      }}
                      value={value}
                    />
                  )}
                />
              </div>
              <div className="w-full flex flex-col items-start justify-center gap-2">
                <h4 className="font-medium">Comuna</h4>
                <Controller
                  name="commune"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <Select
                      label="Seleccione la comuna"
                      options={list_commune}
                      onChange={(e) => {
                        onChange(e)
                        map_update(e)
                      }}
                      value={value}
                    />
                  )}
                />
              </div>

              <>
                <div className="w-full flex flex-col items-start justify-center gap-2">
                  <h4 className="font-medium">a. Zona(s) de exclusión</h4>
                  <Controller
                    name="shippingType"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <Select
                        label="Zona de exclusión"
                        options={list_zones_exclusion}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </div>

                <div className="w-full flex flex-col items-start justify-center gap-2">
                  <h4 className="font-medium">b. Zona(s) de inclusión</h4>
                  <Controller
                    name="shippingType"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <Select
                        label="Zona de inclusión"
                        options={list_zones_inclusion}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </div>
              </>
            </div>
          </div>

          {!!current_commune && false && (
            <div className="w-full flex flex-col p-4 pt-0 pt-1 lg:flex-row">
              <div className="flex flex-col  justify-center content-center gap-6">
                <button
                  type="button"
                  onClick={commune_draw_full_exclution_zone}
                  className={cn(
                    "w-full  p-2 text-sm text-md hover:opacity-80 text-white rounded-md shadow-sm hover:shadow-md transform transition duration-500 hover:scale-105",
                    `bg_${_color}`,
                  )}
                >
                  Utilizar comuna como zona de exclusión
                </button>
              </div>
            </div>
          )}

          {mode === "disabled" && (
            <>
              <div className="w-full flex flex-col px-4 pb-4">
                <a href="#/" className="w-full flex flex-row items-center text-base font-medium text-red-500">
                  <span className="flex text-lg text-red-500 pr-1">+</span> Zonas de exclusión
                </a>
                <a href="#/" className="w-full flex flex-row items-center text-base font-medium text-emerald-500">
                  <span className="flex text-lg text-emerald-500 pr-1">+</span> Zonas de inclusión
                </a>
              </div>
            </>
          )}
        </div>

        <div className="w-full p-4 rounded-lg border bg-white shadow-md">
          {isLoaded && (
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              center={center}
              zoom={10}
              onLoad={(map) => {
                mapRef.current = map
                setMap(map)
                setDrawingManager(new google.maps.drawing.DrawingManager({ map }))
              }}
            >
              {/* Drawing manager */}
              <DrawingManager onPolygonComplete={onPolygonComplete} />

              {/* Render all polygons */}
              {polygons.map((polygon: any, index: number) => (
                <Polygon
                  key={index}
                  paths={polygon.path}
                  options={{
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillOpacity: 0.35,
                    fillColor: "red",
                    strokeColor: "red",
                  }}
                />
              ))}
            </GoogleMap>
          )}
        </div>
      </div>

      <Modal className="w-full h-4/5 lg:w-9/12 px-20 py-8 overflow-auto max-h-[90vh]" ref={modal_ref}>
        <div className="w-full flex flex-row items-center justify-between pb-4">
          <h4 className="font-medium text-xl">Configuración de la zona</h4>

          {zone_dinamic && working_time_field_form.fields.length > 0 && (
            <TrashButton onClick={remove_all_working_time} label="Eliminar todo" />
          )}
        </div>

        <div className="w-full flex flex-col  justify-between pb-2">
          <p className="font-medium text-md pb-2">Identificador de la zona</p>
          <Controller
            name="zone_id"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <TextField
                label="Id"
                onChange={(e: any) => {
                  onChange(e)
                  set_schedule_dinamic_name(e.target.value)
                }}
                value={value}
              />
            )}
          />
        </div>

        <div className="w-full flex flex-col  justify-between pb-2">
          <p className="font-medium text-md pb-2">Tipo de zona</p>
          <Controller
            name="shippingType"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                label="Seleccione una opción"
                options={list_types_zones}
                onChange={(e) => {
                  onChange(e)
                  change_selected_zone_dinamic(e)
                }}
                value={value}
              />
            )}
          />
          {selected_zone_dinamic === "exclusion" && false && (
            <p className="pt-2 pb-4">
              <input type="checkbox" name="zone_exlusion_dinamic" onChange={() => set_zone_dinamic(!zone_dinamic)} />{" "}
              Zona dinámica
            </p>
          )}
        </div>

        {zone_dinamic && (
          <>
            <div className="w-full border-b border-[#919191]"></div>
            <div className="w-full flex flex-col">
              {working_time_field_form.fields.map((workingTimeItem, i) => {
                return (
                  <Coverages.WorkingTimeFields
                    key={workingTimeItem.id}
                    index={i}
                    working_time={working_time_field_form}
                    control={controlCoverages}
                    errors={errorsCoverages}
                    title="Horario"
                  />
                )
              })}

              <div
                className={`w-full flex flex-row items-center justify-start ${
                  working_time_field_form.fields.length ? "px-16" : "px-0 pt-5"
                }`}
              >
                <AddButton onClick={add_new_working_time} />
              </div>
            </div>
          </>
        )}
        <div className="w-full flex flex-col">
          {
            <div className="w-full flex flex-row justify-center gap-6 mt-10 lg:mt-20">
              <button
                type="button"
                onClick={handle_click_close_working_time}
                className={cn(
                  "w-full flex flex-col items-center lg:w-48 p-2 text-center text-base font-semibold border hover:opacity-80 rounded-md shadow-sm hover:shadow-md transform transition duration-500 hover:scale-105",
                  `border_${_color}`,
                  `text_${_color}`,
                )}
              >
                Cerrar
              </button>
              {!!selected_zone_dinamic && (
                <button
                  type="submit"
                  onClick={maps_save_zone_config}
                  className={cn(
                    "w-full flex flex-col items-center lg:w-48 p-2 text-center text-base font-semibold hover:opacity-80 text-white rounded-md shadow-sm hover:shadow-md transform transition duration-500 hover:scale-105",
                    `bg_${_color}`,
                  )}
                >
                  Aplicar
                </button>
              )}
            </div>
          }
        </div>
      </Modal>
    </>
  )
}

export default InitialForm
