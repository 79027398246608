import { Breadcrumb, ButtonGroup, TitleScreen } from "components/Core"
import { FormRoute } from "components/FormEntities"
import useForm from "hooks/useForm"
import useSearchMiddlewareOSRM from "hooks/useSearchMiddlewareOSRM"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import route_values from "utils/forms_default/route_values"
import schemaValidationRoutes from "utils/yup/routes_schema"
import { fill_form_from_data } from "components/FormEntities/FormRoute/route.config"

const EditScreen = () => {
  const { control, reset, watch, setValue, errors } = useForm({
    defaultValues: route_values,
    schema: schemaValidationRoutes,
  })
  const [route, set_route] = useState<any>()
  const { route_id } = useParams()
  const { data: data_routes, search_osrm_action: search_osrm_action_routes } = useSearchMiddlewareOSRM({
    entity: "routes",
  })

  useEffect(() => {
    search_osrm_action_routes({ from: 0, size: 1000, filters: { id: route_id } })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (Boolean(data_routes?.records.length)) {
      const get_route = async () => {
        let route_from_req = data_routes?.records[0]
        set_route(route_from_req)
      }
      get_route()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_routes])

  useEffect(() => {
    // RESET FORM TO FILL FIELDS
    console.log("route", route)
    route && reset(fill_form_from_data(route))
    // eslint-disable-next-line
  }, [route])

  if (!route) return null

  return (
    <div className="w-full h-full flex flex-col items-start justify-start pb-10">
      <Breadcrumb
        data={[
          { name: "Listado de rutas", href: "/routes" },
          { name: "Edición de ruta", href: `/routes/${route_id}/edit` },
        ]}
      />
      <div className="w-full flex flex-col justify-center items-center pt-4 px-6">
        <TitleScreen title="Edición de ruta" string_id={route_id} />
      </div>
      <form className="w-full flex flex-col py-2 px-4">
        <div className="w-full flex flex-row items-center justify-end lg:pb-10 py-2">
          <ButtonGroup />
        </div>
        <FormRoute control={control} route={route} watch={watch} setValue={setValue} errors={errors} edit />
      </form>
    </div>
  )
}

export default EditScreen
