import React from 'react';
import { cn } from 'utils/utils';
import { Source } from 'interfaces/ISlots';
import { SourceCopyButton } from './SourceCopyButton';
import usePreRender from 'hooks/usePreRender';

interface SourceTabsProps {
  sources: Array<Source>;
  activeSource: string;
  onSourceChange: (sourceId: string) => void;
  onLoadMore: () => void;
  onCopySource: (fromSourceId: string, toSourceId: string) => void;
}

export const SourceTabs: React.FC<SourceTabsProps> = ({
  sources,
  activeSource,
  onSourceChange,
  onLoadMore,
  onCopySource,
}) => {
  const { _color } = usePreRender()
  return (
    <div className="w-84 border-r border-gray-200">
    <h2 className="px-4 py-3 font-medium text-gray-700 bg-gray-50 border-b border-gray-200">
      Sources
    </h2>
    <div className="py-4">
      <div className="space-y-1 px-3 max-h-[800px] overflow-y-auto custom-scrollbar">
        {sources.map(({ _id, name }) => (
          <div key={_id} className="flex items-center gap-2">
            <button
              onClick={() => onSourceChange(_id)}
              title={`Name: ${name}\nID: ${_id}`}
              className={cn(
                "flex-1 min-w-0 text-left px-4 py-2.5 rounded-lg text-sm font-medium transition-colors group relative",
                activeSource === _id
                  ? `border border_${_color} text_${_color} bg-gray-50`
                  : "text-gray-600 hover:bg-gray-50"
              )}
            >
              <div className="flex flex-col">
                <span className="truncate">{name}</span>
                <span className="text-xs opacity-60 truncate">ID: {_id}</span>
              </div>
              
              
            </button>
            {activeSource !== _id && (
              <SourceCopyButton
                sourceId={activeSource}
                sourceName={sources.find(s => s._id === activeSource)?.name || ''}
                targetSourceId={_id}
                targetSourceName={name}
                onCopy={onCopySource}
              />
            )}
          </div>
        ))}

            <button
              onClick={() => onLoadMore()}
              title={`load more`}
              className={cn(
                "flex-1 min-w-0 text-left px-4 py-2.5 rounded-lg text-sm font-medium transition-colors group relative",
                `text-white bg_${_color}`
              )}
            >
              <div className="flex flex-col">
                <span className="truncate">Load More</span>
              </div>
              
              
            </button>
      </div>
    </div>
  </div>
  );
}