import { useContext } from "react"
import AuthContext from "store/context/PublicContext"
import { AppContext } from "store/context/AppContext"
import useCancelToken from "hooks/useCancelToken"
import OMNIX_MODULE from "constants/OMNIX_MODULE"
import {
  post_module_entity_action_middleware,
} from "services/middleware_services"
import Swal from "sweetalert2"
import { useNavigate } from "react-router-dom"

// interface IGetCoverageParams {
//   Coverage_id: string
//   params?: any
// }

interface IPostCoverageParams {
  body: any
}

const useCombinedCoverageBFF = () => {
  const navigate = useNavigate()
  const { newCancelToken, isCancel } = useCancelToken()
  const {
    user: { token },
  } = useContext(AuthContext)
  const { loading_app, set_loading_app } = useContext(AppContext)

  const post_create_combined_coverages_action = async ({ body }: IPostCoverageParams) => {
    try {
      set_loading_app(true)
      console.log(body)
      await post_module_entity_action_middleware(OMNIX_MODULE.COMBINED, `coverages`, body, token, newCancelToken())
      Swal.fire({
        title: "Hecho",
        text: "Zonas creadas",
        icon: "success",
      })
      navigate(`/areas`)
      set_loading_app(false)
    } catch (error: any) {
      set_loading_app(false)
      Swal.fire({
        title: "Error",
        text: JSON.stringify(error?.response?.data?.message),
        icon: "error",
      })
      if (isCancel(error)) return
    }
  }

  return {
    post_create_combined_coverages_action,
    loading_app,
    set_loading_app,
  }
}

export default useCombinedCoverageBFF
