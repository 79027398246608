const stock_values = {
  id: "",
  name: "",
  sources: [],
  safety: {
    type: "fixed",
    value: 0,
  },
}

export default stock_values
