import * as yup from "yup"

const schemaValidationChannels = yup.object().shape({
  id: yup.string().required("required-field"),
  name: yup.string().required("required-field"),
  stock: yup.object().shape({
    id: yup.string().required(),
  }),
})

export default schemaValidationChannels
