export const SAFETY_TYPE = [
  { id: 1, label: "Fijo", value: "fixed" },
  { id: 2, label: "Porcentaje", value: "percent" },
]

export const parsed_body_to_send = (_data: any) => {
  const { id, name, sources, safety } = _data

  let data = {
    id,
    name,
    safety: {
      type: safety?.type,
      value: Number(safety?.value)
    },
    sources: sources?.length ? sources?.map((src: any) => src?.id) : [],
  }

  return data
}

export const fill_form_from_data = (_data: any) => {
  const { id, name, sources, safety } = _data

  let data = {
    id,
    name,
    safety,
    sources,
  }

  return data
}
