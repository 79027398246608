import { Breadcrumb, TitleScreen, ButtonGroup } from "components/Core"
import { useNavigate, useParams } from "react-router-dom"
import { useFieldArray } from "react-hook-form"
import schemaValidationSources from "utils/yup/sources_schema"
import useForm from "hooks/useForm"
import source_values from "utils/forms_default/source_values"
import { FormSource } from "components/FormEntities"
import { fill_form_from_data, parsed_body_to_send } from "components/FormEntities/FormSource/source.config"
import useCombinedSourcesBFF from "hooks/bff_entities/useCombinedSourcesBFF"
import { useEffect, useState } from "react"
import Swal from "sweetalert2"

const EditScreen = () => {
  const navigate = useNavigate()
  const [source, set_source] = useState<any>()
  const { source_id } = useParams()
  const { set_loading_app, post_list_source_combined_action, put_upsert_source_combined_action } =
    useCombinedSourcesBFF()
  const { control, errors, watch, reset, handleSubmit, setValue } = useForm({
    defaultValues: source_values,
    schema: schemaValidationSources,
  })

  const location_field_form = useFieldArray({ control, name: "location" })
  const custom_field_form = useFieldArray({ control, name: "custom" })
  const volume_capacity_field_form = useFieldArray({ control, name: "volumeCapacity" })
  const dispatch_capacity_field_form = useFieldArray({ control, name: "dispatchCapacity" })
  const schedule_field_form = useFieldArray({ control, name: "schedule" })
  const crossdock_field_form = useFieldArray({ control, name: "crossdock" })
  const working_sessions_field_form = useFieldArray({ control, name: "workingSessions" })
  const working_time_field_form = useFieldArray({ control, name: "workingTime" })

  useEffect(() => {
    const get_source = async () => {
      try {
        set_loading_app(true)
        const res: any = await post_list_source_combined_action({
          body: { skipRecords: 0, maxRecords: 10, filter: { id: source_id } },
        })
        set_source(res?.data?.message?.records[0] || null)
        set_loading_app(false)
      } catch (error: any) {
        set_loading_app(false)
        Swal.fire({
          title: "Error",
          text: JSON.stringify(error?.response?.data?.message),
          icon: "error",
        })
      }
    }
    get_source()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // RESET FORM TO FILL FIELDS
    source && reset(fill_form_from_data(source))
    // eslint-disable-next-line
  }, [source])

  const on_submit = async (data: any) => {
    try {
      set_loading_app(true)
      const body = parsed_body_to_send(data)
      const res: any = await put_upsert_source_combined_action({ body: { ...body?.OIM, ...body?.OLM } })
      if (res?.data?.code === 200) {
        Swal.fire({
          title: "Hecho",
          text: "Source editado",
          icon: "success",
        })
        navigate(`/sources/${body?.OIM?.id}`)
      }
      set_loading_app(false)
    } catch (error: any) {
      Swal.fire({
        title: "Error",
        text: JSON.stringify(error?.response?.data?.message),
        icon: "error",
      })
      set_loading_app(false)
    }
  }
  console.log("errors", errors)

  if (!source) return null
  return (
    <div className="w-full h-full flex flex-col items-start justify-start pb-10">
      <Breadcrumb
        data={[
          { name: "Listado de sources", href: "/sources" },
          { name: "Detalle de source", href: `/sources/${source_id}` },
          { name: "Editar source", href: `/sources/${source_id}/edit` },
        ]}
      />
      <div className="w-full flex flex-col justify-center items-center pt-4 px-6">
        <TitleScreen title={`Editar source #${source_id}`} />
      </div>
      <form onSubmit={handleSubmit(on_submit)} className="w-full flex flex-col py-2 px-4">
        <div className="w-full flex flex-row items-center justify-end lg:pb-10 py-2">
          <ButtonGroup />
        </div>
        <FormSource
          control={control}
          errors={errors}
          watch={watch}
          setValue={setValue}
          location_field_form={location_field_form}
          custom_field_form={custom_field_form}
          volume_capacity_field_form={volume_capacity_field_form}
          dispatch_capacity_field_form={dispatch_capacity_field_form}
          schedule_field_form={schedule_field_form}
          crossdock_field_form={crossdock_field_form}
          working_sessions_field_form={working_sessions_field_form}
          working_time_field_form={working_time_field_form}
        />
      </form>
    </div>
  )
}

export default EditScreen
