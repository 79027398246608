import React, { useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { VerticalTabs } from './VerticalTabs';
import { DaySchedule } from './DaySchedule';
import { SlotsData } from 'interfaces/ISlots';

export type DaysNumber = "1" | "2" | "3" | "4" | "5" | "6" | "7";
 
const DAYS: {id: DaysNumber, label: string}[]= [
  { id: '1', label: 'Monday' },
  { id: '2', label: 'Tuesday' },
  { id: '3', label: 'Wednesday' },
  { id: '4', label: 'Thursday' },
  { id: '5', label: 'Friday' },
  { id: '6', label: 'Saturday' },
  { id: '7', label: 'Sunday' },
];

interface SourceScheduleProps {
  sourceIndex: number;
  deliveryIndex: number;
  form: UseFormReturn<SlotsData>;
}

export const SourceScheduleC: React.FC<SourceScheduleProps> = ({
  sourceIndex,
  deliveryIndex,
  form
}) => {
  const [activeTab, setActiveTab] = useState<DaysNumber>('1');

  const handleCloneDay = (toDay: DaysNumber) => {
    const fromDaySlots = form.getValues(`sources.${sourceIndex}.schedule.${deliveryIndex}.deliverySlots.${activeTab}`) || [];
    form.setValue(`sources.${sourceIndex}.schedule.${deliveryIndex}.deliverySlots.${toDay}`, fromDaySlots);
  };

  return (
    <div className="flex flex-1">
      <VerticalTabs
        days={DAYS}
        activeTab={activeTab}
        onTabChange={setActiveTab}
        onCloneDay={handleCloneDay}
      />
      
      <div className="flex-1">
        {DAYS.map(({ id }) => (
          <div
            key={id}
            className={activeTab === id ? 'block' : 'hidden'}
          >
            <DaySchedule 
              dayId={`sources.${sourceIndex}.schedule.${deliveryIndex}.deliverySlots.${activeTab === id ? activeTab : id}`}
              form={form} 
            />
          </div>
        ))}
      </div>
    </div>
  );
};