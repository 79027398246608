import { IoClose } from "react-icons/io5"

interface Item {
  id: number;
  name: string;
  code: string;
}

interface SelectedItemsModalProps {
  isOpen: boolean;
  items: Item[];
  onClose: () => void;
  onRemoveItem: (id: number) => void;
  title?: string
}

export default function SelectedItemsModal({
  isOpen,
  items,
  onClose,
  onRemoveItem,
  title = '',
}: SelectedItemsModalProps) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg max-w-2xl w-full max-h-[80vh] overflow-auto">
        <div className="p-4 border-b">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-semibold">{title} Seleccionados</h2>
            <button
              onClick={onClose}
              className="p-1 hover:bg-gray-100 rounded-full"
            >
              <IoClose className="text-lg text-black" />
            </button>
          </div>
        </div>
        <div className="p-4">
          {items.map((item) => (
            <div
              key={item.id}
              className="flex justify-between items-center py-2 border-b last:border-b-0"
            >
              <div>
                <div className="font-medium">{item.name}</div>
                <div className="text-sm text-gray-500">ID: {item.id}</div>
              </div>
              <button
                onClick={() => onRemoveItem(item.id)}
                className="p-1 hover:bg-red-100 rounded-full"
              >
                <IoClose className="text-lg text-red-500" />
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}