import { Breadcrumb, ButtonGroup, TitleScreen } from "components/Core"
import { FormRoute } from "components/FormEntities"
import useForm from "hooks/useForm"
import route_values from "utils/forms_default/route_values"
import schemaValidationRoutes from "utils/yup/routes_schema"
import { parsed_body_to_send } from "components/FormEntities/FormRoute/route.config"
import useRoutesResources from "hooks/entities/useRoutesResources"
import Swal from "sweetalert2"
import { useNavigate } from "react-router-dom"

const NewScreen = () => {
  const navigate = useNavigate()
  const { control, watch, setValue, handleSubmit, errors } = useForm({
    defaultValues: route_values,
    schema: schemaValidationRoutes,
  })
  const { set_loading_app, put_upsert_route_action } = useRoutesResources()

  const on_submit = async (data: any) => {
    try {
      set_loading_app(true)
      const body = parsed_body_to_send(data)
      let res: any = await put_upsert_route_action({ body })
      if (res?.data?.code === 200) {
        Swal.fire({
          title: "Hecho",
          text: "Ruta creada",
          icon: "success",
        })
        navigate(`/routes/${body?.id}`)
      }
      set_loading_app(false)
    } catch (error: any) {
      Swal.fire({
        title: "Error",
        text: JSON.stringify(error?.response?.data?.message),
        icon: "error",
      })
      set_loading_app(false)
    }
  }
  console.log("errors", errors)

  return (
    <div className="w-full h-full flex flex-col items-start justify-start pb-10">
      <Breadcrumb
        data={[
          { name: "Listado de rutas", href: "/routes" },
          { name: "Creación de ruta", href: "/routes/new" },
        ]}
      />
      <div className="w-full flex flex-col justify-center items-center pt-4 px-6">
        <TitleScreen title="creación de ruta" />
      </div>
      <form className="w-full flex flex-col py-2 px-4" onSubmit={handleSubmit(on_submit)}>
        <div className="w-full flex flex-row items-center justify-end lg:pb-10 py-2">
          <ButtonGroup />
        </div>
        <FormRoute control={control} watch={watch} setValue={setValue} errors={errors} />
      </form>
    </div>
  )
}

export default NewScreen
